import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from './resources'
import i18n from 'i18next'

i18n
   .use(LanguageDetector) // For automatic language detection
   .use(initReactI18next) // Initializes react-i18next
   .init({
      resources, // Here you can add your language resources directly if you don't use Backend
      fallbackLng: 'en', // Language to use if the current language can't be found
      debug: false, // Enable for debugging in development
      detection: {
         order: [
            'queryString',
            'cookie',
            'localStorage',
            'navigator',
            'htmlTag',
            'path',
            'subdomain',
         ],
         caches: ['localStorage', 'cookie'], // Cache the detected language
      },
      interpolation: {
         escapeValue: false, // Not needed for React
      },
   })

export default i18n
