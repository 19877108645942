import { ROUTES } from '../../../../models/router/IRoute'
import { RecoveryPassSentContainer } from './styles'
import { SubmitBtn } from '../../../SignUp/styles'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import React from 'react'

const RecoveryPassSent = () => {
   const navigate = useNavigate()

   const handleClick = () => {
      navigate(ROUTES.LOGIN)
   }

   return (
      <RecoveryPassSentContainer>
         <Typography variant="h1"> Password Update Success</Typography>
         <Typography variant="body2">
            The new password has been successfully saved. You can log in with
            the new password
         </Typography>
         <SubmitBtn onClick={handleClick}>
            <Typography>Log in</Typography>
         </SubmitBtn>
      </RecoveryPassSentContainer>
   )
}

export default RecoveryPassSent
