import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IConversation } from '../../models/storeModels/IConveration'
import { ISearchState } from '../../models/storeModels/ISearch'
import { IGlobalState } from '../../models/storeModels'

const initialState: ISearchState = {
   conversationSearchText: '',
   messageSearchText: '',
   filteredMessageList: [],
   filteredConversationList: [],
   isSearchBar: false,
   isMobSearch: false,
}

const searchSlice = createSlice({
   name: 'search',
   initialState,
   reducers: {
      toggleMobSearch: (state, action: PayloadAction<boolean>) => {
         state.isMobSearch = action.payload
      },
      toggleSearchBar: (state, action: PayloadAction<boolean>) => {
         state.isSearchBar = action.payload
      },
      updateMessageSearchText: (state, action: PayloadAction<string>) => {
         state.messageSearchText = action.payload
      },
      updateConversationSearchText: (state, action: PayloadAction<string>) => {
         state.conversationSearchText = action.payload
      },
      setFilteredConversationList: (
         state,
         action: PayloadAction<IConversation[]>
      ) => {
         state.filteredConversationList = action.payload
      },
   },
})

export const {
   toggleMobSearch,
   toggleSearchBar,
   updateMessageSearchText,
   updateConversationSearchText,
   setFilteredConversationList,
} = searchSlice.actions

export const getIsMobSearch = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.search.isMobSearch
)
export const getIsSearchBar = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.search.isSearchBar
)
export const selectSearchText = createSelector(
   (state: IGlobalState) => state,
   (state) => state.search.messageSearchText
)

export const getConversationSearchText = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.search.conversationSearchText
)

export const getConversationFilteredList = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.search.filteredConversationList
)

export default searchSlice.reducer
