import {
   HaveAccountContainer,
   PrivacyPolicyContainer,
   RegistrationFormContainer,
   RegistrationFormHeader,
   RegistrationFormWrapper,
   SubmitBtn,
} from './styles'
import {
   InteractionEvents,
   PageNames,
   UserEvents,
} from '../../models/analitycs'
import InputPasswordAdornment from '../../components/common/InputPasswordAdorment'
import useAnalyticsPageView from '../../hooks/Analitycs/useAnalyticsPageView'
import { registerUser, selectUser } from '../../store/slices/userSlice'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import AnalyticsService from '../../services/analyticsService'
import { fetchProfile } from '../../store/slices/profileSlice'
import { FormInput } from '../../components/common/FormInput'
import { useAppDispatch, useAppSelector } from '../../hooks'
import HeaderLogo from '../../components/common/HeaderLogo'
import { IUser } from '../../models/storeModels/IUser'
import { yupResolver } from '@hookform/resolvers/yup'
import { ROUTES } from '../../models/router/IRoute'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { shallowEqual } from 'react-redux'
import React, { useState } from 'react'
import * as Yup from 'yup'

interface IFormInput {
   email: string
   password: string
}

const SignUp = () => {
   const [showPassword, setShowPassword] = useState(false)
   const user = useAppSelector(selectUser, shallowEqual) as IUser
   const navigate = useNavigate()
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   useAnalyticsPageView(PageNames.SIGNUP)

   const validationSchema = Yup.object().shape({
      email: Yup.string()
         .email(t('validation_messages.invalidEmailFormat'))
         .required(t('validation_messages.required')),
      password: Yup.string()
         .min(8, t('validation_messages.passLength', { count: 8 }))
         .matches(/[A-Z]/, t('validation_messages.passUppercase'))
         .matches(/[^a-zA-Z0-9]/, t('validation_messages.passSpecialChar'))
         .required(t('validation_messages.required')),
   })

   const {
      handleSubmit,
      reset,
      setError,
      formState: { errors, isValid, isDirty, isLoading },
      control,
   } = useForm({
      resolver: yupResolver(validationSchema),
      mode: 'onBlur',
   })
   const disabledSubmitBtn = !isValid || !isDirty || isLoading

   const handleShowPassword = () => setShowPassword((show) => !show)

   const handleLoginClick = () => {
      AnalyticsService.track(
         InteractionEvents.CTA_REDIRECTED_TO_LOGIN_FROM_SIGNUP_CLICKED,
         {}
      )
      navigate(ROUTES.LOGIN)
   }

   const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
      try {
         const { email, password } = data
         await dispatch(registerUser({ email, password })).unwrap()
         reset()
         await dispatch(fetchProfile())
         AnalyticsService.track(UserEvents.SIGN_UP, {
            user_id: user?.id,
            user_email: user?.email,
            user_is_activated: user?.isActivated,
         })
         AnalyticsService.identify(user?.id)
         navigate(ROUTES.HOME)
      } catch (e) {
         setError('email', {
            type: 'manual',
            message: t('validation_messages.emailAlreadyRegistered'),
         })
      }
   }

   return (
      <RegistrationFormWrapper>
         <RegistrationFormHeader>
            <HeaderLogo pageName={PageNames.SIGNUP} />
         </RegistrationFormHeader>
         <RegistrationFormContainer>
            <Typography variant="h1">{t('pages.signup.title')}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                     <FormInput
                        label={t('common.email')}
                        variant="outlined"
                        {...field}
                        error={!!errors?.email}
                        helperText={errors?.email ? errors?.email?.message : ''}
                     />
                  )}
               />
               <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                     <FormInput
                        label={t('common.password')}
                        variant="outlined"
                        {...field}
                        error={!!errors?.password}
                        helperText={
                           errors?.password ? errors?.password?.message : ''
                        }
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                           endAdornment: (
                              <InputPasswordAdornment
                                 onClick={handleShowPassword}
                                 isOpen={showPassword}
                              />
                           ),
                        }}
                     />
                  )}
               />
               <PrivacyPolicyContainer variant="body2">
                  {t('pages.signup.subtitle')}
               </PrivacyPolicyContainer>

               <SubmitBtn type="submit" disabled={disabledSubmitBtn}>
                  <Typography> {t('pages.signup.btn')}</Typography>
               </SubmitBtn>
               <HaveAccountContainer variant="body2">
                  {t('pages.signup.haveAnAccount')}
                  <span onClick={handleLoginClick}>
                     {t('pages.signup.login')}
                  </span>
               </HaveAccountContainer>
            </form>
         </RegistrationFormContainer>
      </RegistrationFormWrapper>
   )
}

export default SignUp
