import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const RecoveryPassFormContainer = styled(Box)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 378px;
   height: 100%;
   box-sizing: border-box;
   h1 {
      margin: 0 0 24px 0;
   }
   button {
      margin: 24px 0 0 0;
   }
   form {
      margin: 48px 0 0 0;
      width: inherit;
      display: flex;
      flex-direction: column;
   }

   @media (max-width: 600px) {
      width: 100%;
   }
`
