import AnalyticsService from '../../../../services/analyticsService'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { FormInput } from '../../../../components/common/FormInput'
import { selectUser } from '../../../../store/slices/userSlice'
import { IUser } from '../../../../models/storeModels/IUser'
import { checkMail } from '../../../../services/api/userApi'
import { UserEvents } from '../../../../models/analitycs'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitBtn } from '../../../SignUp/styles'
import { useAppSelector } from '../../../../hooks'
import { ForgotPassFormContainer } from './styles'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { shallowEqual } from 'react-redux'
import * as Yup from 'yup'
import React from 'react'

interface ForgotPassFormParams {
   callback: React.Dispatch<React.SetStateAction<number>>
}

interface IFormInput {
   email: string
}

const ForgotPassForm = ({ callback }: ForgotPassFormParams) => {
   const user = useAppSelector(selectUser, shallowEqual) as IUser
   const { t } = useTranslation()

   const validationSchema = Yup.object().shape({
      email: Yup.string()
         .email(t('validation_messages.invalidEmailFormat'))
         .required(t('validation_messages.required')),
   })

   const {
      handleSubmit,
      control,
      formState: { errors, isValid, isDirty, isLoading },
      setError,
   } = useForm<IFormInput>({
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
   })

   const disabledSubmitBtn = !isValid || !isDirty || isLoading

   const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
      try {
         const { email } = data
         if (email) {
            await checkMail(email)
               .then((res) => res.data?.isExist && callback(2))
               .catch((e) => {
                  setError('email', {
                     type: 'manual',
                     message: e.response?.data?.message,
                  })
               })
            AnalyticsService.track(UserEvents.FORGOT_PASS_SUBMITTED, {})
            AnalyticsService.identify(user?.id)
         }
      } catch (e) {
         console.log(e)
      }
   }

   return (
      <ForgotPassFormContainer>
         <Typography variant="h1">{t('pages.forgot_pass.title')}</Typography>
         <Typography variant="body2">
            {t('pages.forgot_pass.subtitle')}
         </Typography>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
               name="email"
               control={control}
               defaultValue=""
               render={({ field }) => (
                  <FormInput
                     label={t('common.email')}
                     variant="outlined"
                     {...field}
                     error={!!errors?.email}
                     helperText={errors?.email ? errors?.email?.message : ''}
                  />
               )}
            />
            <SubmitBtn type="submit" disabled={disabledSubmitBtn}>
               <Typography>{t('pages.forgot_pass.btn')}</Typography>
            </SubmitBtn>
         </form>
      </ForgotPassFormContainer>
   )
}

export default ForgotPassForm
