import { Box, CircularProgress, Modal } from '@mui/material'
import { ModalType } from '../../models/storeModels/IModal'
import { closeModal } from '../../store/slices/modalSlice'
import { IGlobalState } from '../../models/storeModels'
import React, { FC, lazy, Suspense } from 'react'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { shallowEqual } from 'react-redux'
import { styled } from '@mui/system'

const MODALS: Record<ModalType, FC> = {
   CREATE_CONVERSATION: lazy(() => import('./CreateConversationModal')),
   DELETE_CONVERSATION: lazy(() => import('./DeleteConversationModal')),
   DELETE_MESSAGE: lazy(() => import('./DeleteMessageModal')),
   DELETE_ACCOUNT: lazy(() => import('./DeleteAccountModal')),
   BUG_REPORT: lazy(() => import('./BugReportModal')),
}

export const ModalContainer = styled(Modal)`
   display: flex;
   align-items: center;
   justify-content: center;
`

const GlobalModal: FC = () => {
   const dispatch = useAppDispatch()
   const modal = useAppSelector(
      (state: IGlobalState) => state.modal,
      shallowEqual
   )
   const handleClose = () => {
      dispatch(closeModal())
   }

   const renderModal = () => {
      const ModalComponent = MODALS[modal.type as ModalType]
      return ModalComponent ? <ModalComponent /> : null
   }

   return (
      <ModalContainer
         open={modal.open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
      >
         <Suspense
            fallback={
               <Box>
                  <CircularProgress />
               </Box>
            }
         >
            {renderModal()}
         </Suspense>
      </ModalContainer>
   )
}

export default GlobalModal
