const translation = {
   common: {
      cancel: '취소',
      delete: '삭제',
      create: '생성',
      check: '확인',
      send: '보내기',
      update: '업데이트',
      title: '제목',
      subtitle: '부제목',
      search: '검색',
      name: '이름',
      description: '설명',
      description_optional: '설명 (선택 사항)',
      email: '이메일',
      password: '비밀번호',
   },
   search: {
      empty: '메시지가 없습니다',
      found: '메시지를 찾았습니다',
   },
   context_menu: {
      settings: '설정',
      report_bug: '버그 신고',
      logout: '로그아웃',
      delete_chat: '채팅 삭제',
      delete: '삭제',
      copy: '복사',
   },
   pages: {
      signup: {
         title: '계정 생성',
         subtitle:
            '가입하거나 로그인하면 이용 약관 및 개인정보 처리방침에 동의하는 것입니다',
         btn: '계정 생성',
         haveAnAccount: '계정이 있나요?',
         login: '로그인',
      },
      login: {
         title: '로그인',
         forgotPass: '비밀번호를 잊어버리셨나요?',
         btn: '로그인',
         howTo: '더 가깝게 어떻게 될 수 있을까요?',
         createAccount: '계정 생성',
      },
      forgot_pass: {
         title: '비밀번호 재설정',
         subtitle: '이메일을 입력하면 비밀번호 재설정 링크를 보내드립니다',
         btn: '링크 보내기',
         title2: '지침 전송됨',
         subtitle2:
            "비밀번호를 새로 설정하기 위한 링크가 이메일 주소로 전송되었습니다. '받은 편지함' 폴더를 확인하고, 이메일이 '스팸' 폴더로 이동되었는지도 확인하세요.",
         backBtn: '홈으로',
      },
      recovery_pass: {
         title: '비밀번호 재설정',
         subtitle:
            '거의 다 됐어요! 강력한 새 비밀번호를 만들고 다시 활동을 시작하세요!',
         new_pass: '새 비밀번호',
         confirm_pass: '비밀번호 확인',
         btn: '새 비밀번호 저장',
      },
      unavailable: {
         title: '이 페이지를 사용할 수 없습니다',
         subtitle: '죄송합니다, 이곳에 접근할 수 없습니다',
         btn: '홈',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: '대화 상대 선택',
         subtitle: '대화하고 싶은 사람의 이름 또는 별명을 입력해주세요',
         description_hint: '최적의 검색 결과를 위해 설명을 입력해 주세요',
         back_label_link: '대화 상대를 찾지 못하셨나요?',
      },
      report_bug: {
         title: '버그 신고',
         subtitle: '문제를 자세히 설명하거나 피드백을 제공해주세요.',
         title_placeholder: '보고서의 주제를 입력하세요...',
         subtitle_placeholder: '보고서 내용에 대한 자세한 개요를 입력하세요...',
      },
      delete_conversation: {
         title: '대화 삭제',
         subtitle: '이 대화를 삭제하시겠습니까?',
      },
      delete_message: {
         title: '메시지 삭제',
         subtitle: '이 메시지를 삭제하시겠습니까?',
      },
   },
   conversation: {
      placeholder: '메시지 작성...',
   },
   settings: {
      title: '설정',
      privacy_and_security: '개인정보 및 보안',
      language: '언어',
      report_bug: '버그 신고',
      terms_of_use: '이용 약관',
   },
   profile: {
      title: '프로필 편집',
      bio: '자기 소개',
      bio_optional: '자기 소개 (선택 사항)',
      bio_hint:
         '대화 상대가 당신에 대해 알아야 할 내용을 자기 소개에 적어주세요. 이 정보는 더 정확하고 맞춤화된 답변을 제공하는 데 도움이 됩니다.',
   },
   validation_messages: {
      required: '이 필드는 필수입니다.',
      titleMaxLength: '제목은 최대 {{count}}자까지 가능합니다.',
      invalidEmailFormat: '잘못된 이메일 형식입니다.',
      passLength: '비밀번호는 최소 {{count}}자 이상이어야 합니다',
      passUppercase: '비밀번호는 최소 한 개의 대문자를 포함해야 합니다',
      passSpecialChar: '비밀번호는 최소 한 개의 특수 문자를 포함해야 합니다',
      emailAlreadyRegistered: '이 이메일은 이미 등록되어 있습니다.',
      failedLogin: '로그인에 실패했습니다. 자격 증명을 확인해주세요.',
      matchPass: '비밀번호가 일치해야 합니다',
      confirmPass: '비밀번호 확인이 필요합니다',
   },
}

export default translation
