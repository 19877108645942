import {
   ForgotPasswordContainer,
   GoogleBtn,
   GoogleIco,
   HowToBeyondContainer,
   LoginFormContainer,
   LoginFormHeader,
   LoginFormWrapper,
   SubmitBtn,
   TextContainer,
} from './styles'
import {
   loginGoogleUser,
   loginUser,
   selectLoading,
   selectUser,
} from '../../store/slices/userSlice'
import {
   InteractionEvents,
   PageNames,
   UserEvents,
} from '../../models/analitycs'
import InputPasswordAdornment from '../../components/common/InputPasswordAdorment'
import useAnalyticsPageView from '../../hooks/Analitycs/useAnalyticsPageView'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CircularProgress } from '../../components/common/Buttons'
import AnalyticsService from '../../services/analyticsService'
import { fetchProfile } from '../../store/slices/profileSlice'
import { FormInput } from '../../components/common/FormInput'
import { useAppDispatch, useAppSelector } from '../../hooks'
import HeaderLogo from '../../components/common/HeaderLogo'
import { IUser } from '../../models/storeModels/IUser'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGoogleLogin } from '@react-oauth/google'
import { ROUTES } from '../../models/router/IRoute'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LOGIN_TYPES } from '../../constants'
import { Typography } from '@mui/material'
import { shallowEqual } from 'react-redux'
import React, { useState } from 'react'
import * as Yup from 'yup'

interface IFormInput {
   email: string
   password: string
}

const Login = () => {
   const [loginType, setLoginType] = useState<string | null>(null)
   const [showPassword, setShowPassword] = useState<boolean>(false)
   const user = useAppSelector(selectUser, shallowEqual) as IUser
   const loading = useAppSelector(selectLoading, shallowEqual)

   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const { t } = useTranslation()
   useAnalyticsPageView(PageNames.LOGIN)

   const validationSchema = Yup.object().shape({
      email: Yup.string()
         .email(t('validation_messages.invalidEmailFormat'))
         .required(t('validation_messages.required')),
      password: Yup.string()
         .min(8, t('validation_messages.passLength', { count: 8 }))
         .required(t('validation_messages.required')),
   })

   const {
      handleSubmit,
      reset,
      setError,
      formState: { errors, isValid, isDirty, isLoading },
      control,
   } = useForm({
      resolver: yupResolver(validationSchema),
      mode: 'onBlur',
   })
   const disabledSubmitBtn = !isValid || !isDirty || isLoading

   const handleShowPassword = () => setShowPassword((show) => !show)

   const handleForgotPassClick = () => {
      AnalyticsService.track(InteractionEvents.FORGOT_PASS_BTN_CLICKED, {})
      navigate(ROUTES.FORGOT_PASS)
   }

   const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
      try {
         const { email, password } = data
         setLoginType(LOGIN_TYPES.BY_MAIL)
         await dispatch(loginUser({ email, password })).unwrap()
         reset()
         await dispatch(fetchProfile())
         AnalyticsService.track(UserEvents.LOGIN, {
            user_id: user?.id,
            user_email: user?.email,
            user_is_activated: user?.isActivated,
         })
         AnalyticsService.identify(user?.id)
         navigate(ROUTES.HOME)
      } catch (e) {
         console.log(e)
         setError('password', {
            type: 'manual',
            message: t('validation_messages.failedLogin'),
         })
         setError('email', {
            type: 'manual',
            message: '',
         })
      } finally {
         setLoginType(null)
      }
   }

   const handleSignUpClick = () => {
      AnalyticsService.track(
         InteractionEvents.CTA_REDIRECTED_TO_SIGNUP_FROM_LOGIN_CLICKED,
         {}
      )
      navigate(ROUTES.SIGNUP)
   }

   const handleGoogleLogin = useGoogleLogin({
      onSuccess: async (codeResponse) => {
         try {
            setLoginType(LOGIN_TYPES.GOOGLE)
            await dispatch(loginGoogleUser(codeResponse)).unwrap()
            reset()
            await dispatch(fetchProfile())
            AnalyticsService.track(UserEvents.LOGIN, {
               user_id: user?.id,
               user_email: user?.email,
               user_is_activated: user?.isActivated,
            })
            AnalyticsService.identify(user?.id)

            navigate(ROUTES.HOME)
         } catch (e) {
            console.error(e)
         } finally {
            setLoginType(null)
         }
      },
      flow: 'auth-code',
   })

   return (
      <LoginFormWrapper>
         <LoginFormHeader>
            <HeaderLogo pageName={PageNames.LOGIN} />
         </LoginFormHeader>
         <LoginFormContainer>
            <Typography variant="h1">{t('pages.login.title')}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
               <GoogleBtn onClick={handleGoogleLogin}>
                  {loginType === LOGIN_TYPES.GOOGLE && loading ? (
                     <CircularProgress size={24} />
                  ) : (
                     <Typography variant="body1">
                        <GoogleIco />
                        Continue with Google
                     </Typography>
                  )}
               </GoogleBtn>
               <TextContainer>
                  <Typography variant="body1">or</Typography>
               </TextContainer>
               <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                     <FormInput
                        label={t('common.email')}
                        variant="outlined"
                        {...field}
                        error={!!errors?.email}
                        helperText={errors?.email ? errors?.email?.message : ''}
                     />
                  )}
               />
               <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                     <FormInput
                        label={t('common.password')}
                        variant="outlined"
                        {...field}
                        error={!!errors?.password}
                        helperText={
                           errors?.password ? errors?.password?.message : ''
                        }
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                           endAdornment: (
                              <InputPasswordAdornment
                                 onClick={handleShowPassword}
                                 isOpen={showPassword}
                              />
                           ),
                        }}
                     />
                  )}
               />
               <ForgotPasswordContainer
                  variant="body2"
                  onClick={handleForgotPassClick}
               >
                  {t('pages.login.forgotPass')}
               </ForgotPasswordContainer>
               <SubmitBtn type="submit" disabled={disabledSubmitBtn}>
                  {loginType === LOGIN_TYPES.BY_MAIL && loading ? (
                     <CircularProgress size={24} />
                  ) : (
                     <Typography>{t('pages.login.btn')}</Typography>
                  )}
               </SubmitBtn>
               <HowToBeyondContainer variant="body2">
                  {t('pages.login.howTo')}
                  <span onClick={handleSignUpClick}>
                     {t('pages.login.createAccount')}
                  </span>
               </HowToBeyondContainer>
            </form>
         </LoginFormContainer>
      </LoginFormWrapper>
   )
}

export default Login
