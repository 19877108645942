import AnalyticsService from '../../services/analyticsService'
import { selectUser } from '../../store/slices/userSlice'
import { IUser } from '../../models/storeModels/IUser'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../index'
import { useEffect } from 'react'

const useAnalyticsPageView = (pageName: any) => {
   const location = useLocation()
   const user = (useAppSelector(selectUser) as IUser) || null

   useEffect(() => {
      AnalyticsService.page({
         page_name: pageName,
         path_name: `${location.pathname}`,
         ...(user
            ? {
                 user_id: user.id,
                 user_email: user.email,
                 user_activated: user.isActivated,
              }
            : { user: null }),
      })
   }, [])
}

export default useAnalyticsPageView
