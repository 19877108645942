import { ErrorContainer, ErrorContent, ErrorHeaderContainer } from './styles'
import smallLogo from '../../assets/img/logo/logo_small_Be.svg'
import { Typography, useMediaQuery } from '@mui/material'
import logo from '../../assets/img/logo/logo.svg'
import { device } from '../../theme'
import React from 'react'

const Error = () => {
   const isMobile = useMediaQuery(() => device.xs.max)

   return (
      <ErrorContainer>
         <ErrorHeaderContainer>
            <img src={isMobile ? smallLogo : logo} alt="be-closer-logo" />
         </ErrorHeaderContainer>
         <ErrorContent>
            <Typography variant={'h1'}>404</Typography>
            <Typography variant={'body1'}>
               Oops! Something went wrong.
            </Typography>
         </ErrorContent>
      </ErrorContainer>
   )
}

export default Error
