import { ReactComponent as VisibilityOffIco } from '@material-design-icons/svg/filled/visibility_off.svg'
import { ReactComponent as VisibilityIco } from '@material-design-icons/svg/filled/visibility.svg'
import { styled } from '@mui/system'

export const VisibilityOff = styled(VisibilityOffIco)`
   fill: ${({ theme }) => theme.palette.white.secondary};
   cursor: pointer;

   &:hover {
      fill: ${({ theme }) => theme.palette.white.primary};
   }
`
export const Visibility = styled(VisibilityIco)`
   cursor: pointer;
   fill: ${({ theme }) => theme.palette.white.secondary};

   &:hover {
      fill: ${({ theme }) => theme.palette.white.primary};
   }
`
