import InputAdornment from '@mui/material/InputAdornment'
import { Visibility, VisibilityOff } from './styles'
import React from 'react'

interface Props {
   isOpen: boolean
   onClick: () => void
}
const InputPasswordAdornment: React.FC<Props> = ({ isOpen, onClick }) => {
   return (
      <InputAdornment position="end" onClick={onClick}>
         {isOpen ? <VisibilityOff /> : <Visibility />}
      </InputAdornment>
   )
}

export default InputPasswordAdornment
