import { ReactComponent as Google } from '../../assets/img/google.svg'
import { SignUpBtn } from '../../components/common/Buttons'
import { PrivacyPolicyContainer } from '../SignUp/styles'
import { device } from '../../theme'
import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const LoginFormWrapper = styled(Box)`
   user-select: none;
   background: ${({ theme }) => theme.palette.main.background};
   box-shadow: 0 20px 40px 0 rgba(49, 34, 46, 0.2);
   cursor: default;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   gap: 16px;
   width: 100%;
   padding: 32px 122px 40px 122px;

   @media ${device.md.max} {
      padding: 20px 40px;
      align-items: center;
   }
   @media (max-width: 600px) {
      padding: 20px;
   }
`
export const LoginFormHeader = styled(Box)`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
`

export const LoginFormContainer = styled(Box)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 378px;
   margin: 0 50px;
   height: 100%;
   box-sizing: border-box;

   form {
      margin: 48px 0 0 0;
      width: inherit;
      display: flex;
      flex-direction: column;
   }

   @media (max-width: 600px) {
      width: 100%;
   }
`

export const SubmitBtn = styled(SignUpBtn)`
   width: 100%;
`
export const ForgotPasswordContainer = styled(PrivacyPolicyContainer)`
   text-align: center;
   margin: 24px 0;
   cursor: pointer;

   &:hover {
      color: ${({ theme }) => theme.palette.white.primary};
   }
`

export const HowToBeyondContainer = styled(PrivacyPolicyContainer)`
   font-weight: 700;

   span {
      margin: 0 4px;
      cursor: pointer;
      color: ${({ theme }) => theme.palette.white.primary};
   }
`

export const GoogleBtn = styled(SignUpBtn)`
   width: 100%;
   p {
      display: flex;
      align-items: center;
      gap: 6px;
   }
`

export const GoogleIco = styled(Google)``

export const TextContainer = styled(Box)`
   margin: 18px 0 12px 0;
   display: flex;
   justify-content: center;
   align-items: center;
`
