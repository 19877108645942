import useAnalyticsPageView from '../../hooks/Analitycs/useAnalyticsPageView'
import { RecoveryPassHeader, RecoveryPassWrapper } from './styles'
import RecoveryPassForm from './components/RecoveryPassForm'
import RecoveryPassSent from './components/RecoveryPassSent'
import HeaderLogo from '../../components/common/HeaderLogo'
import { PageNames } from '../../models/analitycs'
import React, { useState } from 'react'

const RecoveryPass: React.FC = () => {
   const [currStep, setCurrStep] = useState<number>(1)
   useAnalyticsPageView(PageNames.RECOVERY_PASS)

   const getRenderStep = (step: number) => {
      const steps: Record<number, React.ReactElement> = {
         1: <RecoveryPassForm callback={setCurrStep} />,
         2: <RecoveryPassSent />,
      }

      return steps[step] || <></>
   }
   return (
      <RecoveryPassWrapper>
         <RecoveryPassHeader>
            <HeaderLogo pageName={PageNames.RECOVERY_PASS} />
         </RecoveryPassHeader>
         {getRenderStep(currStep)}
      </RecoveryPassWrapper>
   )
}

export default RecoveryPass
