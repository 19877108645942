import HeaderLogo from '../../../../../../components/common/HeaderLogo'
import { MobHeaderContainer, SignUpBtn, LoginBtn } from './styles'
import { PageNames } from '../../../../../../models/analitycs'
import { Typography } from '@mui/material'
import React from 'react'

interface Props {
   handleLogin: () => void
   handleSignUp: () => void
}

const MobHeader: React.FC<Props> = ({ handleLogin, handleSignUp }) => {
   return (
      <MobHeaderContainer>
         <SignUpBtn onClick={handleSignUp}>
            <Typography variant={'body1'}>Sign up</Typography>
         </SignUpBtn>
         <HeaderLogo pageName={PageNames.DASHBOARD} isMob={true} />
         <LoginBtn onClick={handleLogin}>Log in</LoginBtn>
      </MobHeaderContainer>
   )
}

export default MobHeader
