import {
   ActivateContainer,
   ActivateContent,
   ActivateHeaderContainer,
   HomeBtn,
} from './styles'
import smallLogo from '../../assets/img/logo/logo_small_Be.svg'
import React, { useEffect, useState, useCallback } from 'react'
import { activateAccount } from '../../services/api/userApi'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, useMediaQuery } from '@mui/material'
import { ROUTES } from '../../models/router/IRoute'
import logo from '../../assets/img/logo/logo.svg'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'
import { device } from '../../theme'

const Activate = () => {
   const isMobile = useMediaQuery(() => device.xs.max)
   const navigate = useNavigate()
   const { t } = useTranslation()
   const { link } = useParams()
   const [message, setMessage] = useState('Activation...')

   const debouncedActivateAccount = useCallback(
      debounce((link) => {
         activateAccount(link)
            .then((res) => {
               setMessage(res?.data?.message)
            })
            .catch((e) => {
               setMessage(e?.response?.data?.message || 'An error occurred')
            })
      }, 300),
      []
   )

   useEffect(() => {
      if (link) {
         debouncedActivateAccount(link)
      }
   }, [link, debouncedActivateAccount])

   const handleClick = () => {
      navigate(ROUTES.HOME)
   }

   return (
      <ActivateContainer>
         <ActivateHeaderContainer>
            <img
               src={isMobile ? smallLogo : logo}
               alt="be-closer-logo"
               onClick={handleClick}
            />
         </ActivateHeaderContainer>
         <ActivateContent>
            <Typography variant={'h3'}>{message}</Typography>
            <HomeBtn onClick={handleClick}>
               <Typography>{t('pages.unavailable.btn')}</Typography>
            </HomeBtn>
         </ActivateContent>
      </ActivateContainer>
   )
}

export default Activate
