import { IGetMessagesParams } from '../../models/response/messageResponse'
import api from '../apiService'

const url = '/message'

export const createMessage = async (conversationId: string, text: string) => {
   return api.post(url, { text, conversation_id: conversationId })
}

export const getAllMessages = async (conversationId: string) => {
   const params: IGetMessagesParams = {
      conversation_id: conversationId,
   }
   return api.get(url, { params })
}

export const deleteMessage = async (
   conversationId: string,
   messageId: string
) => {
   const data = { conversation_id: conversationId, id: messageId }
   await api.delete(url, { data })
}
