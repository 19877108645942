import { ROUTES } from '../../../../models/router/IRoute'
import { SubmitBtn } from '../../../SignUp/styles'
import { ForgotPassSentContainer } from './styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import React from 'react'

const ForgotPassSent = () => {
   const navigate = useNavigate()
   const { t } = useTranslation()

   const handleClick = () => {
      navigate(ROUTES.HOME)
   }

   return (
      <ForgotPassSentContainer>
         <Typography variant="h1">{t('pages.forgot_pass.title2')}</Typography>
         <Typography variant="body2">
            {t('pages.forgot_pass.subtitle2')}
         </Typography>
         <SubmitBtn onClick={handleClick}>
            <Typography>{t('pages.forgot_pass.backBtn')}</Typography>
         </SubmitBtn>
      </ForgotPassSentContainer>
   )
}

export default ForgotPassSent
