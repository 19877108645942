import { Device, IPalette, ISize } from './models/ITheme'
import { createTheme, Theme } from '@mui/material'

const size: ISize = {
   xs: '480px',
   sm: '768px',
   md: '1024px',
   lg: '1260px',
   xl: '1440px',
}
const sizeMin: ISize = {
   xs: '481px',
   sm: '769px',
   md: '1025px',
   lg: '1261px',
   xl: '1441px',
}

export const device: Device = {
   xs: {
      max: `(max-width: ${size.xs})`,
      min: `(min-width: ${sizeMin.xs})`,
   },
   sm: {
      max: `(max-width: ${size.sm})`,
      min: `(min-width: ${sizeMin.sm} )`,
   },
   md: {
      max: `(max-width: ${size.md})`,
      min: `(min-width: ${sizeMin.md} )`,
   },
   lg: {
      max: `(max-width: ${size.lg})`,
      min: `(min-width: ${sizeMin.lg})`,
   },
   xl: {
      max: `(max-width: ${size.xl})`,
      min: `(min-width: ${sizeMin.xl})`,
   },
}

const palette: IPalette = {
   mode: 'dark',
   transparent: 'transparent',
   black: { primary: '#121212', secondary: '#222222' },
   gray: { primary: '#302F2F', secondary: '#363636' },
   white: { primary: '#FFFFFF', secondary: '#A8A8A8' },
   purple: {
      primary: '#63455D',
   },
   blue: {
      primary: '#4C5578',
   },
   lime: {
      primary: '#FFFF00',
      secondary: '#fae69e',
   },
   red: {
      primary: '#DF2B26',
   },
   line: 'linear-gradient(90deg, rgba(52, 52, 52, 0.22) 0%, rgba(69, 67, 67, 0.9) 30.73%, rgba(69, 67, 67, 0.9) 67.19%, rgba(52, 52, 52, 0.22) 95.83%)',
   modal: {
      background: '#222222CC',
      hover: 'rgba(51, 37, 37, 0.8)',
   },
   main: {
      background: '#121212',
   },
   dashboard: {
      border: {
         variant1: '#7a6d78',
         variant2: '#E293D2',
         variant3: '#745C6F',
         variant4: '#F5A8E6',
         variant5: '#613157',
      },
      background: {
         variant1:
            'linear-gradient( 118deg, rgba(137, 122, 134, 0.16) 0.56%, rgba(119, 90, 114, 0.16) 42.08%, rgba(99, 69, 93, 0.24) 74.09%, rgba(99, 69, 93, 0.32) 108.28% );',
         variant2:
            'linear-gradient(118deg, rgba(253, 200, 242, 0.12) 0.56%, rgba(235, 138, 215, 0.12) 20.19%, rgba(234, 135, 214, 0.15) 74.09%, rgba(234, 136, 214, 0.18) 99.3%);',
         variant3:
            'linear-gradient(118deg, rgba(170, 150, 166, 0.12) 0.56%, rgba(105, 80, 100, 0.12) 28.61%, rgba(90, 68, 86, 0.15) 74.09%, rgba(57, 38, 54, 0.18) 108.28%);',
      },
      variant4:
         'linear-gradient( 118deg, rgba(84, 71, 81, 0.12) 0.56%, rgba(87, 65, 83, 0.12) 28.61%, rgba(85, 35, 75, 0.15) 74.09%, rgba(75, 21, 65, 0.18) 108.28% );',
      variant5:
         'linear-gradient( 118deg, rgba(253, 200, 242, 0.12) 0.56%, rgba(235, 138, 215, 0.12) 20.19%, rgba(234, 135, 214, 0.15) 74.09%, rgba(234, 136, 214, 0.18) 99.3% );',
   },
}

const typography = {
   fontFamily: 'Manrope, sans-serif',
   body1: {
      color: palette.white.primary,
      fontFamily: 'Manrope, sans-serif',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
   },
   body2: {
      color: palette.white.secondary,
      fontFamily: 'Manrope, sans-serif',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
   },
   subtitle1: {
      color: palette.white.secondary,
      fontFamily: 'Manrope, sans-serif',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
   },
   subtitle2: {
      color: palette.white.primary,
      fontFamily: 'Manrope, sans-serif',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
   },
   h1: {
      color: palette.white.primary,
      fontFamily: 'Manrope, sans-serif',
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%',
   },
   h2: {
      color: palette.white.primary,
      fontFamily: 'Manrope, sans-serif',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '130%',
   },
   h3: {
      color: palette.white.primary,
      fontFamily: 'Manrope, sans-serif',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '130%',
   },
   h4: {
      color: palette.white.primary,
      fontFamily: 'Manrope, sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '130%',
   },
   button: {
      color: palette.white.primary,
      fontWeight: 600,
      fontFamily: 'Manrope, sans-serif',
   },
}

const theme: Theme = createTheme({
   typography,
   palette,
})

export default theme
