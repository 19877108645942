import {
   drumHiddenAnimation,
   drumRevealHiddenAnimation,
} from '../styles/animation'
import { css, styled } from '@mui/system'
import { Box } from '@mui/material'

export const HeaderLogoContainer = styled(Box)<{
   isScrolled: boolean
   hasRendered: boolean
}>`
   transition: all 0.3s ease-in-out;
   cursor: pointer;
   height: 22px;
   display: flex;
   align-items: center;
   user-select: none;
   gap: 5px;
   overflow: hidden;
   img {
      pointer-events: none;
   }

   img:last-of-type {
      padding: ${({ hasRendered }) => (hasRendered ? '0' : '0 0 0 4px')};
   }

   img:nth-of-type(3) {
      display: ${({ hasRendered }) => (hasRendered ? 'block' : 'none')};
      animation: ${({ isScrolled }) =>
         isScrolled
            ? css`
                 ${drumRevealHiddenAnimation} 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1 forwards
              `
            : css`
                 ${drumHiddenAnimation} 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1 forwards
              `};
      overflow: hidden;
   }

   @media (max-width: 600px) {
      img:nth-of-type(3) {
         display: none;
      }
   }
`
