import { SignUpBtn as Btn } from '../../../../../../components/common/Buttons'
import { device } from '../../../../../../theme'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const DesktopHeaderContainer = styled(Box)`
   width: inherit;
   display: flex;
   justify-content: space-between;
   box-sizing: border-box;
   align-items: center;
   padding: 20px 122px;
   background: ${({ theme }) => theme.palette.black.primary};

   @media ${device.md.max} {
      padding: 20px 40px;
   }
   @media (max-width: 600px) {
      padding: 20px;
   }
`

export const DesktopHeaderBtnGroup = styled(Box)`
   display: flex;
   align-items: center;
   box-sizing: border-box;
   gap: 24px;
`

export const SignUpBtn = styled(Btn)`
   border-radius: 24px;
   width: 194px;
   height: 45px;
`

export const LoginBtn = styled(Typography)`
   cursor: pointer;
   font-weight: 600;
`
