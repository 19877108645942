import { ProfileData } from '../../models/storeModels/IProfile'
import api from '../apiService'

export const updateProfile = async (data: ProfileData) => {
   try {
      const response = await api.put(`/profile`, data)
      return response.data
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const getProfile = async () => {
   try {
      return api.get('/profile')
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const uploadAvatar = async (avatarFile: File) => {
   try {
      const formData = new FormData()
      formData.append('avatar', avatarFile)
      const response = await api.post('/profile/avatar', formData)
      return response.data
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const deleteAvatar = async () => {
   try {
      const response = await api.delete('/profile/avatar')
      return response.data
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}
