const translation = {
   common: {
      cancel: 'скасувати',
      delete: 'видалити',
      create: 'створити',
      check: 'перевірити',
      send: 'надіслати',
      update: 'оновити',
      title: 'заголовок',
      subtitle: 'підзаголовок',
      search: 'Пошук',
      name: 'Ім’я',
      description: 'Опис',
      description_optional: 'Опис (необов’язково)',
      email: 'Електронна пошта',
      password: 'Пароль',
   },
   search: {
      empty: 'Повідомлень не знайдено',
      found: 'повідомлень знайдено',
   },
   context_menu: {
      settings: 'Налаштування',
      report_bug: 'Повідомити про помилку',
      logout: 'Вийти',
      delete_chat: 'Видалити чат',
      delete: 'Видалити',
      copy: 'Копіювати',
   },
   pages: {
      signup: {
         title: 'Створити акаунт',
         subtitle:
            'Реєструючись або входячи, ви погоджуєтесь з нашими Умовами використання та Політикою конфіденційності',
         btn: 'Створити акаунт',
         haveAnAccount: 'Вже є акаунт?',
         login: 'Увійти',
      },
      login: {
         title: 'Увійти',
         forgotPass: 'Забули ваш пароль?',
         btn: 'Увійти',
         howTo: 'Як бути ближчим?',
         createAccount: 'Створити акаунт',
      },
      forgot_pass: {
         title: 'Скидання пароля',
         subtitle:
            'Введіть вашу електронну пошту, і ми надішлемо посилання для скидання пароля',
         btn: 'Надіслати посилання',
         title2: 'Інструкції надіслано',
         subtitle2:
            "Електронний лист із посиланням для встановлення нового пароля було відправлено на вашу адресу електронної пошти. Будь ласка, перевірте папку 'Вхідні', а також папку 'Спам' вашої електронної пошти. Лист може бути туди спрямовано.",
         backBtn: 'Повернутися на головну',
      },
      recovery_pass: {
         title: 'Скидання пароля',
         subtitle:
            'Майже готово! Створіть новий надійний пароль і повертайтеся до дії!',
         new_pass: 'Новий пароль',
         confirm_pass: 'Підтвердіть пароль',
         btn: 'Зберегти новий пароль',
      },
      unavailable: {
         title: 'Ця сторінка недоступна',
         subtitle: 'Вибачте, ви не можете отримати доступ до цієї сторінки.',
         btn: 'На головну',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Оберіть вашого співрозмовника',
         subtitle:
            'Будь ласка, введіть ім’я та прізвище або псевдонім особи, з якою ви хочете спілкуватися',
         description_hint:
            'Для оптимальних результатів пошуку, будь ласка, надайте опис',
         back_label_link: 'Не знайшли вашого співрозмовника?',
      },
      report_bug: {
         title: 'Повідомити про помилку',
         subtitle:
            'Будь ласка, детально опишіть вашу проблему або надайте відгук.',
         title_placeholder: 'Введіть основну тему вашого звіту...',
         subtitle_placeholder: 'Введіть детальний огляд вмісту звіту...',
      },
      delete_conversation: {
         title: 'Видалити розмову',
         subtitle: 'Ви впевнені, що хочете видалити цю розмову?',
      },
      delete_message: {
         title: 'Видалити повідомлення',
         subtitle: 'Ви впевнені, що хочете видалити це повідомлення?',
      },
   },
   conversation: {
      placeholder: 'Написати повідомлення...',
   },
   settings: {
      title: 'Налаштування',
      privacy_and_security: 'Конфіденційність та безпека',
      language: 'Мова',
      report_bug: 'Повідомити про помилку',
      terms_of_use: 'Умови використання',
   },
   profile: {
      title: 'Редагувати профіль',
      bio: 'Біографія',
      bio_optional: 'Біографія (необов’язково)',
      bio_hint:
         'У вашій біографії, будь ласка, поділіться тим, що ви хотіли б, щоб ваш співрозмовник знав про вас. Це допоможе отримувати відповіді, які краще та точніше відповідають вашим потребам.',
   },
   validation_messages: {
      required: 'Це поле є обов’язковим.',
      titleMaxLength: 'Заголовок може бути максимум {{count}} символів.',
      invalidEmailFormat: 'Неправильний формат електронної пошти.',
      passLength: 'Пароль повинен бути принаймні {{count}} символів',
      passUppercase: 'Пароль повинен містити принаймні одну велику літеру',
      passSpecialChar:
         'Пароль повинен містити принаймні один спеціальний символ',
      emailAlreadyRegistered: 'Ця електронна пошта вже зареєстрована.',
      failedLogin:
         'Не вдалося увійти. Будь ласка, перевірте свої дані для входу.',
      matchPass: 'Паролі повинні співпадати',
      confirmPass: 'Підтвердження пароля є обов’язковим',
   },
}

export default translation
