const translation = {
   common: {
      cancel: 'إلغاء',
      delete: 'حذف',
      create: 'إنشاء',
      check: 'تحقق',
      send: 'إرسال',
      update: 'تحديث',
      title: 'العنوان',
      subtitle: 'العنوان الفرعي',
      search: 'بحث',
      name: 'الاسم',
      description: 'الوصف',
      description_optional: 'الوصف (اختياري)',
      email: 'البريد الإلكتروني',
      password: 'كلمة المرور',
   },
   search: {
      empty: 'لم يتم العثور على رسائل',
      found: 'تم العثور على الرسائل',
   },
   context_menu: {
      settings: 'الإعدادات',
      report_bug: 'الإبلاغ عن خطأ',
      logout: 'تسجيل خروج',
      delete_chat: 'حذف المحادثة',
      delete: 'حذف',
      copy: 'نسخ',
   },
   pages: {
      signup: {
         title: 'إنشاء حساب',
         subtitle:
            'بالتسجيل أو تسجيل الدخول، أنت توافق على شروط الاستخدام وسياسة الخصوصية',
         btn: 'إنشاء حساب',
         haveAnAccount: 'لديك حساب؟',
         login: 'تسجيل دخول',
      },
      login: {
         title: 'تسجيل الدخول',
         forgotPass: 'هل نسيت كلمة المرور؟',
         btn: 'تسجيل الدخول',
         howTo: 'كيف تكون أقرب؟',
         createAccount: 'إنشاء حساب',
      },
      forgot_pass: {
         title: 'إعادة تعيين كلمة المرور',
         subtitle:
            'أدخل بريدك الإلكتروني وسنرسل لك رابطًا لإعادة تعيين كلمة المرور',
         btn: 'إرسال الرابط',
         title2: 'تم إرسال التعليمات',
         subtitle2:
            'تم إرسال بريد إلكتروني يحتوي على رابط لتعيين كلمة مرور جديدة إلى عنوان بريدك الإلكتروني. بالإضافة إلى التحقق من مجلد "الوارد"، يرجى التحقق أيضًا من مجلد "الرسائل غير المرغوب فيها". قد يتم توجيه البريد الإلكتروني هناك.',
         backBtn: 'الرجوع إلى الرئيسية',
      },
      recovery_pass: {
         title: 'إعادة تعيين كلمة المرور',
         subtitle:
            'تقريبًا هناك! قم بإنشاء كلمة مرور قوية جديدة وعد إلى العمل!',
         new_pass: 'كلمة المرور الجديدة',
         confirm_pass: 'تأكيد كلمة المرور',
         btn: 'حفظ كلمة المرور الجديدة',
      },
      unavailable: {
         title: 'هذه الصفحة غير متوفرة',
         subtitle: 'عذرًا، لا يمكنك الوصول إلى هذا',
         btn: 'الرئيسية',
      },
      dashboard: {},
      modals: {
         create_interlocutor: {
            title: 'اختر محدثك',
            subtitle:
               'الرجاء إدخال الاسم الأول والاسم الأخير أو اللقب للشخص الذي ترغب في التحدث معه',
            description_hint: 'للحصول على أفضل نتائج بحث، يرجى تقديم وصف',
            back_label_link: 'لم تجد محدثك؟',
         },
         report_bug: {
            title: 'الإبلاغ عن خطأ',
            subtitle: 'يرجى توضيح مشكلتك أو تقديم رأيك.',
            title_placeholder: 'اكتب الموضوع الرئيسي للتقرير...',
            subtitle_placeholder: 'أدخل نظرة عامة مفصلة عن محتوى التقرير...',
         },
         delete_conversation: {
            title: 'حذف المحادثة',
            subtitle: 'هل أنت متأكد من أنك تريد حذف هذه المحادثة؟',
         },
         delete_message: {
            title: 'حذف الرسالة',
            subtitle: 'هل أنت متأكد من أنك تريد حذف هذه الرسالة؟',
         },
      },
      conversation: {
         placeholder: 'اكتب رسالة...',
      },
      settings: {
         title: 'الإعدادات',
         privacy_and_security: 'الخصوصية والأمان',
         language: 'اللغة',
         report_bug: 'الإبلاغ عن خطأ',
         terms_of_use: 'شروط الاستخدام',
      },
      profile: {
         title: 'تعديل الملف الشخصي',
         bio: 'السيرة الذاتية',
         bio_optional: 'السيرة الذاتية (اختياري)',
         bio_hint:
            'ضمن سيرتك الذاتية، يرجى مشاركة ما ترغب في معرفته عن شريك المحادثة. سيساعد هذا في تقديم ردود أفضل وأكثر دقة لاحتياجاتك.',
      },
      validation_messages: {
         required: 'هذا الحقل مطلوب.',
         titleMaxLength: 'يمكن أن يكون العنوان في الغالب {{count}} حرفًا.',
         invalidEmailFormat: 'تنسيق البريد الإلكتروني غير صالح.',
         passLength: 'يجب أن تكون كلمة المرور على الأقل {{count}} حرفًا',
         passUppercase: 'يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل',
         passSpecialChar: 'يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل',
         emailAlreadyRegistered: 'هذا البريد الإلكتروني مسجل بالفعل.',
         failedLogin:
            'فشل تسجيل الدخول. يرجى التحقق من بيانات الاعتماد الخاصة بك.',
         matchPass: 'يجب أن تتطابق كلمات المرور',
         confirmPass: 'تأكيد كلمة المرور مطلوب',
      },
   },
}

export default translation
