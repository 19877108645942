const translation = {
   common: {
      cancel: 'отмена',
      delete: 'удалить',
      create: 'создать',
      check: 'проверить',
      send: 'отправить',
      update: 'обновить',
      title: 'заголовок',
      subtitle: 'подзаголовок',
      search: 'Поиск',
      name: 'Имя',
      description: 'Описание',
      description_optional: 'Описание (необязательно)',
      email: 'Имейл',
      password: 'Пароль',
   },
   search: {
      empty: 'Сообщения не найдены',
      found: 'сообщений найдено',
   },
   context_menu: {
      settings: 'Настройки',
      report_bug: 'Сообщить об ошибке',
      logout: 'Выйти',
      delete_chat: 'Удалить чат',
      delete: 'Удалить',
      copy: 'Копировать',
   },
   pages: {
      signup: {
         title: 'Создать аккаунт',
         subtitle:
            'Регистрируясь или входя, вы соглашаетесь с нашими Условиями использования и Политикой конфиденциальности',
         btn: 'Создать аккаунт',
         haveAnAccount: 'Уже есть аккаунт?',
         login: 'Войти',
      },
      login: {
         title: 'Вход',
         forgotPass: 'Забыли пароль?',
         btn: 'Войти',
         howTo: 'Как быть ближе?',
         createAccount: 'Создать аккаунт',
      },
      forgot_pass: {
         title: 'Сброс пароля',
         subtitle: 'Введите ваш email, и мы отправим ссылку для сброса пароля',
         btn: 'Отправить ссылку',
         title2: 'Инструкции отправлены',
         subtitle2:
            "На ваш адрес электронной почты было отправлено письмо со ссылкой для установки нового пароля. Помимо проверки папки 'Входящие', также проверьте папку 'Спам'. Письмо могло быть направлено туда.",
         backBtn: 'На главную',
      },
      recovery_pass: {
         title: 'Сброс пароля',
         subtitle:
            'Почти готово! Создайте новый надежный пароль и приступайте к действиям!',
         new_pass: 'Новый пароль',
         confirm_pass: 'Подтвердите пароль',
         btn: 'Сохранить новый пароль',
      },
      unavailable: {
         title: 'Эта страница недоступна',
         subtitle: 'Извините, вы не можете получить доступ к этому ресурсу',
         btn: 'Главная',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Выберите вашего собеседника',
         subtitle:
            'Пожалуйста, введите имя и фамилию или псевдоним человека, с которым вы хотите общаться',
         description_hint:
            'Для оптимальных результатов поиска предоставьте описание',
         back_label_link: 'Не нашли своего собеседника?',
      },
      report_bug: {
         title: 'Сообщить об ошибке',
         subtitle:
            'Пожалуйста, детально опишите вашу проблему или предоставьте отзыв.',
         title_placeholder: 'Введите основную тему вашего отчета...',
         subtitle_placeholder:
            'Введите подробное описание содержания отчета...',
      },
      delete_conversation: {
         title: 'Удалить разговор',
         subtitle: 'Вы уверены, что хотите удалить этот разговор?',
      },
      delete_message: {
         title: 'Удалить сообщение',
         subtitle: 'Вы уверены, что хотите удалить это сообщение?',
      },
   },
   conversation: {
      placeholder: 'Напишите сообщение...',
   },
   settings: {
      title: 'Настройки',
      privacy_and_security: 'Конфиденциальность и безопасность',
      language: 'Язык',
      report_bug: 'Сообщить об ошибке',
      terms_of_use: 'Условия использования',
   },
   profile: {
      title: 'Редактировать профиль',
      bio: 'Био',
      bio_optional: 'Био (необязательно)',
      bio_hint:
         'В вашем био расскажите, что вы хотели бы, чтобы ваш собеседник узнал о вас. Это поможет получить ответы, которые лучше подходят и более точно соответствуют вашим потребностям.',
   },
   validation_messages: {
      required: 'Это поле обязательно.',
      titleMaxLength: 'Заголовок может содержать максимум {{count}} символов.',
      invalidEmailFormat: 'Неверный формат email.',
      passLength: 'Пароль должен быть длиной не менее {{count}} символов',
      passUppercase: 'Пароль должен содержать хотя бы одну заглавную букву',
      passSpecialChar:
         'Пароль должен содержать хотя бы один специальный символ',
      emailAlreadyRegistered: 'Этот email уже зарегистрирован.',
      failedLogin:
         'Не удалось войти. Пожалуйста, проверьте ваши учетные данные.',
      matchPass: 'Пароли должны совпадать',
      confirmPass: 'Требуется подтверждение пароля',
   },
}

export default translation
