const translation = {
   common: {
      cancel: 'zrušit',
      delete: 'smazat',
      create: 'vytvořit',
      check: 'zkontrolovat',
      send: 'odeslat',
      update: 'aktualizovat',
      title: 'titulek',
      subtitle: 'podtitul',
      search: 'Hledat',
      name: 'Jméno',
      description: 'Popis',
      description_optional: 'Popis (volitelný)',
      email: 'Email',
      password: 'Heslo',
   },
   search: {
      empty: 'Zprávy nenalezeny',
      found: 'zpráv nalezeno',
   },
   context_menu: {
      settings: 'Nastavení',
      report_bug: 'Nahlásit chybu',
      logout: 'Odhlásit se',
      delete_chat: 'Smazat chat',
      delete: 'Smazat',
      copy: 'Kopírovat',
   },
   pages: {
      signup: {
         title: 'Vytvořit účet',
         subtitle:
            'Registrací nebo přihlášením souhlasíte s našimi Podmínkami použití a Zásadami ochrany osobních údajů',
         btn: 'Vytvořit účet',
         haveAnAccount: 'Máte účet?',
         login: 'Přihlásit se',
      },
      login: {
         title: 'Přihlásit se',
         forgotPass: 'Zapomněli jste heslo?',
         btn: 'Přihlásit se',
         howTo: 'Jak být blíže?',
         createAccount: 'Vytvořit účet',
      },
      forgot_pass: {
         title: 'Obnovit heslo',
         subtitle:
            'Zadejte svůj email a my vám pošleme odkaz pro obnovení hesla',
         btn: 'Odeslat odkaz',
         title2: 'Instrukce odeslány',
         subtitle2:
            "Na vaši emailovou adresu byl odeslán email s odkazem pro nastavení nového hesla. Kromě kontrolní složky 'Doručené' prosím zkontrolujte také složku 'Spam'. Email mohl být přesměrován tam.",
         backBtn: 'Zpět domů',
      },
      recovery_pass: {
         title: 'Obnovit heslo',
         subtitle:
            'Už to skoro máte! Vytvořte silné nové heslo a vrátíte se zpět do akce!',
         new_pass: 'Nové heslo',
         confirm_pass: 'Potvrdit heslo',
         btn: 'Uložit nové heslo',
      },
      unavailable: {
         title: 'Tato stránka není dostupná',
         subtitle: 'Omlouváme se, nemůžete přistupovat',
         btn: 'Domů',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Vyberte svého partnera',
         subtitle:
            'Zadejte křestní jméno a příjmení nebo přezdívku osoby, s kterou chcete komunikovat',
         description_hint:
            'Pro optimální výsledky hledání prosím poskytněte popis',
         back_label_link: 'Nenašli jste svého partnera?',
      },
      report_bug: {
         title: 'Nahlásit chybu',
         subtitle:
            'Prosím podrobně popište váš problém nebo poskytněte zpětnou vazbu.',
         title_placeholder: 'Zadejte hlavní téma vaší zprávy…',
         subtitle_placeholder: 'Zadejte podrobný přehled obsahu zprávy…',
      },
      delete_conversation: {
         title: 'Smazat konverzaci',
         subtitle: 'Opravdu chcete smazat tuto konverzaci?',
      },
      delete_message: {
         title: 'Smazat zprávu',
         subtitle: 'Opravdu chcete smazat tuto zprávu?',
      },
   },
   conversation: {
      placeholder: 'Napsat zprávu…',
   },
   settings: {
      title: 'Nastavení',
      privacy_and_security: 'Ochrana osobních údajů a bezpečnost',
      language: 'Jazyk',
      report_bug: 'Nahlásit chybu',
      terms_of_use: 'Podmínky použití',
   },
   profile: {
      title: 'Upravit profil',
      bio: 'Bio',
      bio_optional: 'Bio (volitelný)',
      bio_hint:
         'V rámci vašeho životopisu prosím sdílejte informace, které byste chtěli, aby váš konverzační partner o vás věděl. To pomůže v dostávání odpovědí, které jsou lépe přizpůsobeny vašim potřebám.',
   },
   validation_messages: {
      required: 'Toto pole je povinné.',
      titleMaxLength: 'Název může mít nejvýše {{count}} znaků.',
      invalidEmailFormat: 'Neplatný formát emailu.',
      passLength: 'Heslo musí mít alespoň {{count}} znaků',
      passUppercase: 'Heslo musí obsahovat alespoň jedno velké písmeno',
      passSpecialChar: 'Heslo musí obsahovat alespoň jeden speciální znak',
      emailAlreadyRegistered: 'Tento email je již registrován.',
      failedLogin:
         'Přihlášení se nezdařilo. Zkontrolujte své přihlašovací údaje.',
      matchPass: 'Hesla se musejí shodovat',
      confirmPass: 'Potvrzení hesla je povinné',
   },
}

export default translation
