const translation = {
   common: {
      cancel: 'iptal',
      delete: 'sil',
      create: 'oluştur',
      check: 'kontrol et',
      send: 'gönder',
      update: 'güncelle',
      title: 'başlık',
      subtitle: 'alt başlık',
      search: 'Ara',
      name: 'Ad',
      description: 'Açıklama',
      description_optional: 'Açıklama (isteğe bağlı)',
      email: 'E-posta',
      password: 'Şifre',
   },
   search: {
      empty: 'Mesaj bulunamadı',
      found: 'mesaj bulundu',
   },
   context_menu: {
      settings: 'Ayarlar',
      report_bug: 'Hata bildir',
      logout: 'Çıkış yap',
      delete_chat: 'Sohbeti sil',
      delete: 'Sil',
      copy: 'Kopyala',
   },
   pages: {
      signup: {
         title: 'Bir hesap oluştur',
         subtitle:
            'Kaydolurken veya giriş yaparken Kullanım Şartlarımızı ve Gizlilik Politikamızı kabul etmiş olursunuz',
         btn: 'Hesap Oluştur',
         haveAnAccount: 'Bir hesabınız var mı?',
         login: 'Giriş yap',
      },
      login: {
         title: 'Giriş yap',
         forgotPass: 'Şifrenizi mi unuttunuz?',
         btn: 'Giriş yap',
         howTo: 'Nasıl > Daha Yakın Olunur?',
         createAccount: 'Hesap Oluştur',
      },
      forgot_pass: {
         title: 'Şifreyi sıfırla',
         subtitle:
            'E-postanızı girin ve şifre sıfırlama bağlantısını gönderelim',
         btn: 'Bağlantıyı Gönder',
         title2: 'Talimatlar Gönderildi',
         subtitle2:
            "Yeni bir şifre belirlemek için bir bağlantı içeren e-posta adresinize gönderildi. 'Gelen Kutusu' klasörünüze ek olarak lütfen e-posta 'Spam' klasörünüzü de kontrol edin. E-posta oraya yönlendirilmiş olabilir.",
         backBtn: 'Ana Sayfa’ya Dön',
      },
      recovery_pass: {
         title: 'Şifreyi sıfırla',
         subtitle:
            'Neredeyse oradasınız! Güçlü bir yeni şifre oluşturun ve harekete geçin!',
         new_pass: 'Yeni şifre',
         confirm_pass: 'Şifreyi onayla',
         btn: 'Yeni Şifreyi Kaydet',
      },
      unavailable: {
         title: 'Bu sayfa mevcut değil',
         subtitle: 'Üzgünüz, buna erişemezsiniz',
         btn: 'Ana Sayfa',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Muhatap seçin',
         subtitle:
            'İletişim kurmak istediğiniz kişinin adını ve soyadını veya takma adını girin',
         description_hint:
            'Optimal arama sonuçları için lütfen bir açıklama sağlayın',
         back_label_link: "Muhatap'ınızı bulamadınız mı?",
      },
      report_bug: {
         title: 'Hata bildir',
         subtitle:
            'Lütfen sorununuzu detaylandırın veya geri bildirimde bulunun.',
         title_placeholder: 'Raporunuzun ana konusunu yazın…',
         subtitle_placeholder:
            'Rapor içeriğine dair detaylı bir genel bakış girin…',
      },
      delete_conversation: {
         title: 'Konuşmayı Sil',
         subtitle: 'Bu konuşmayı silmek istediğinizden emin misiniz?',
      },
      delete_message: {
         title: 'Mesajı Sil',
         subtitle: 'Bu mesajı silmek istediğinizden emin misiniz?',
      },
   },
   conversation: {
      placeholder: 'Bir mesaj yazın…',
   },
   settings: {
      title: 'Ayarlar',
      privacy_and_security: 'Gizlilik ve Güvenlik',
      language: 'Dil',
      report_bug: 'Hata bildir',
      terms_of_use: 'Kullanım Şartları',
   },
   profile: {
      title: 'Profili Düzenle',
      bio: 'Biyografi',
      bio_optional: 'Biyografi (isteğe bağlı)',
      bio_hint:
         'Biyografinizde, sohbet partnerinizin sizin hakkınızda ne öğrenmesini istediğinizi paylaşın. Bu, ihtiyaçlarınıza daha iyi uyum sağlayan ve daha kesin yanıtlar sunmada yardımcı olacaktır.',
   },
   validation_messages: {
      required: 'Bu alan gereklidir.',
      titleMaxLength: 'Başlık en fazla {{count}} karakter olabilir.',
      invalidEmailFormat: 'Geçersiz e-posta formatı.',
      passLength: 'Şifre en az {{count}} karakter olmalıdır',
      passUppercase: 'Şifre en az bir büyük harf içermelidir',
      passSpecialChar: 'Şifre en az bir özel karakter içermelidir',
      emailAlreadyRegistered: 'Bu e-posta zaten kayıtlı.',
      failedLogin: 'Giriş başarısız. Kimlik bilgilerinizi kontrol edin.',
      matchPass: 'Şifreler eşleşmeli',
      confirmPass: 'Şifre Onayı gereklidir',
   },
}

export default translation
