const translation = {
   common: {
      cancel: 'cancelar',
      delete: 'eliminar',
      create: 'crear',
      check: 'verificar',
      send: 'enviar',
      update: 'actualizar',
      title: 'título',
      subtitle: 'subtítulo',
      search: 'Buscar',
      name: 'Nombre',
      description: 'Descripción',
      description_optional: 'Descripción (opcional)',
      email: 'Correo electrónico',
      password: 'Contraseña',
   },
   search: {
      empty: 'No se encontraron mensajes',
      found: 'mensajes encontrados',
   },
   context_menu: {
      settings: 'Configuraciones',
      report_bug: 'Reportar error',
      logout: 'Cerrar sesión',
      delete_chat: 'Eliminar chat',
      delete: 'Eliminar',
      copy: 'Copiar',
   },
   pages: {
      signup: {
         title: 'Crear una cuenta',
         subtitle:
            'Al registrarte o iniciar sesión, aceptas nuestros Términos de uso y Política de privacidad',
         btn: 'Crear una cuenta',
         haveAnAccount: '¿Ya tienes una cuenta?',
         login: 'Iniciar sesión',
      },
      login: {
         title: 'Iniciar sesión',
         forgotPass: '¿Olvidaste tu contraseña?',
         btn: 'Iniciar sesión',
         howTo: '¿Cómo estar más > Cerca?',
         createAccount: 'Crear una cuenta',
      },
      forgot_pass: {
         title: 'Restablecer contraseña',
         subtitle:
            'Introduce tu correo electrónico y te enviaremos un enlace para restablecer la contraseña',
         btn: 'Enviar enlace',
         title2: 'Instrucciones enviadas',
         subtitle2:
            "Se ha enviado un correo electrónico con un enlace para establecer una nueva contraseña a tu dirección de correo electrónico. Además de revisar tu carpeta de 'Entrada', verifica también la carpeta de 'Spam'. El correo podría haber sido dirigido allí.",
         backBtn: 'Volver a inicio',
      },
      recovery_pass: {
         title: 'Restablecer contraseña',
         subtitle:
            '¡Casi listo! Crea una nueva contraseña fuerte y vuelve a la acción!',
         new_pass: 'Nueva contraseña',
         confirm_pass: 'Confirmar contraseña',
         btn: 'Guardar nueva contraseña',
      },
      unavailable: {
         title: 'Esta página no está disponible',
         subtitle: 'Lo siento, no puedes acceder a esto',
         btn: 'Inicio',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Elige tu interlocutor',
         subtitle:
            'Por favor, introduce el nombre y apellido o apodo de la persona con la que deseas comunicarte',
         description_hint:
            'Para obtener resultados óptimos en la búsqueda, proporciona una descripción',
         back_label_link: '¿No encontraste a tu interlocutor?',
      },
      report_bug: {
         title: 'Reportar error',
         subtitle: 'Por favor, detalla tu problema o proporciona comentarios.',
         title_placeholder: 'Introduce el tema principal de tu informe…',
         subtitle_placeholder:
            'Introduce una descripción detallada del contenido del informe…',
      },
      delete_conversation: {
         title: 'Eliminar conversación',
         subtitle: '¿Estás seguro de querer eliminar esta conversación?',
      },
      delete_message: {
         title: 'Eliminar mensaje',
         subtitle: '¿Estás seguro de querer eliminar este mensaje?',
      },
   },
   conversation: {
      placeholder: 'Escribe un mensaje…',
   },
   settings: {
      title: 'Configuraciones',
      privacy_and_security: 'Privacidad y seguridad',
      language: 'Idioma',
      report_bug: 'Reportar error',
      terms_of_use: 'Términos de uso',
   },
   profile: {
      title: 'Editar perfil',
      bio: 'Biografía',
      bio_optional: 'Biografía (opcional)',
      bio_hint:
         'En tu biografía, comparte lo que te gustaría que tu compañero de conversación supiera sobre ti. Esto ayudará a obtener respuestas que estén mejor adaptadas y sean más precisas según tus necesidades.',
   },
   validation_messages: {
      required: 'Este campo es obligatorio.',
      titleMaxLength: 'El título puede tener hasta {{count}} caracteres.',
      invalidEmailFormat: 'Formato de correo electrónico no válido.',
      passLength: 'La contraseña debe tener al menos {{count}} caracteres',
      passUppercase: 'La contraseña debe contener al menos una letra mayúscula',
      passSpecialChar:
         'La contraseña debe contener al menos un carácter especial',
      emailAlreadyRegistered: 'Este correo electrónico ya está registrado.',
      failedLogin: 'Error al iniciar sesión. Por favor, verifica tus datos.',
      matchPass: 'Las contraseñas deben coincidir',
      confirmPass: 'Se requiere confirmar la contraseña',
   },
}

export default translation
