const translation = {
   common: {
      cancel: 'キャンセル',
      delete: '削除',
      create: '作成',
      check: '確認',
      send: '送信',
      update: '更新',
      title: 'タイトル',
      subtitle: 'サブタイトル',
      search: '検索',
      name: '名前',
      description: '説明',
      description_optional: '説明 (任意)',
      email: 'メール',
      password: 'パスワード',
   },
   search: {
      empty: 'メッセージが見つかりません',
      found: 'メッセージが見つかりました',
   },
   context_menu: {
      settings: '設定',
      report_bug: 'バグを報告',
      logout: 'ログアウト',
      delete_chat: 'チャットを削除',
      delete: '削除',
      copy: 'コピー',
   },
   pages: {
      signup: {
         title: 'アカウント作成',
         subtitle:
            '登録またはログインすることで、私たちの利用規約とプライバシーポリシーに同意したことになります',
         btn: 'アカウントを作成',
         haveAnAccount: 'アカウントを持っていますか？',
         login: 'ログイン',
      },
      login: {
         title: 'サインイン',
         forgotPass: 'パスワードを忘れましたか？',
         btn: 'ログイン',
         howTo: 'どうやって > もっと近づく？',
         createAccount: 'アカウントを作成',
      },
      forgot_pass: {
         title: 'パスワードをリセット',
         subtitle:
            'メールアドレスを入力してください。パスワードリセットのリンクを送信します',
         btn: 'リンクを送信',
         title2: '指示を送信しました',
         subtitle2:
            "新しいパスワードを設定するリンクを含むメールがあなたのメールアドレスに送信されました。'受信トレイ'だけでなく、メールの'Spam'フォルダーも確認してください。メールがそちらに向かっている可能性があります。",
         backBtn: 'ホームに戻る',
      },
      recovery_pass: {
         title: 'パスワードをリセット',
         subtitle:
            'もう少しで完了！強力な新しいパスワードを作成して、アクションに戻りましょう！',
         new_pass: '新しいパスワード',
         confirm_pass: 'パスワードの確認',
         btn: '新しいパスワードを保存',
      },
      unavailable: {
         title: 'このページは利用できません',
         subtitle: '申し訳ございません、これにアクセスすることはできません',
         btn: 'ホーム',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: '相手を選択',
         subtitle:
            'コミュニケーションをとりたい人の名前やニックネームを入力してください',
         description_hint: '最適な検索結果のために、説明を提供してください',
         back_label_link: '相手が見つかりませんでしたか？',
      },
      report_bug: {
         title: 'バグを報告',
         subtitle: '問題を詳しく説明するか、フィードバックを提供してください。',
         title_placeholder: 'レポートの主題を入力してください…',
         subtitle_placeholder: 'レポートの内容の詳細な概要を入力してください…',
      },
      delete_conversation: {
         title: '会話を削除',
         subtitle: 'この会話を削除してもよろしいですか？',
      },
      delete_message: {
         title: 'メッセージを削除',
         subtitle: 'このメッセージを削除してもよろしいですか？',
      },
   },
   conversation: {
      placeholder: 'メッセージを書く…',
   },
   settings: {
      title: '設定',
      privacy_and_security: 'プライバシーとセキュリティ',
      language: '言語',
      report_bug: 'バグを報告',
      terms_of_use: '利用規約',
   },
   profile: {
      title: 'プロフィールを編集',
      bio: '自己紹介',
      bio_optional: '自己紹介 (任意)',
      bio_hint:
         '自己紹介内で、会話の相手にあなたのことを知ってもらいたい内容を共有してください。これにより、より適切で正確な返答を提供できるようになります。',
   },
   validation_messages: {
      required: 'このフィールドは必須です。',
      titleMaxLength: 'タイトルは最大{{count}}文字までです。',
      invalidEmailFormat: 'メールの形式が無効です。',
      passLength: 'パスワードは最低{{count}}文字必要です',
      passUppercase:
         'パスワードには大文字が少なくとも1文字含まれている必要があります',
      passSpecialChar: 'パスワードには特殊文字が少なくとも1つ必要です',
      emailAlreadyRegistered: 'このメールはすでに登録されています。',
      failedLogin: 'ログインに失敗しました。資格情報を確認してください。',
      matchPass: 'パスワードが一致しなければなりません',
      confirmPass: 'パスワードの確認は必須です',
   },
}

export default translation
