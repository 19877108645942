import InputPasswordAdornment from '../../../../components/common/InputPasswordAdorment'
import AnalyticsService from '../../../../services/analyticsService'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { FormInput } from '../../../../components/common/FormInput'
import { selectUser } from '../../../../store/slices/userSlice'
import { IUser } from '../../../../models/storeModels/IUser'
import { resetPass } from '../../../../services/api/userApi'
import { UserEvents } from '../../../../models/analitycs'
import { yupResolver } from '@hookform/resolvers/yup'
import { RecoveryPassFormContainer } from './styles'
import { SubmitBtn } from '../../../SignUp/styles'
import { useAppSelector } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { shallowEqual } from 'react-redux'
import React, { useState } from 'react'
import * as Yup from 'yup'

interface IFormInput {
   newPass: string
   confirmNewPass: string
}

interface RecoveryPassFormParams {
   callback: React.Dispatch<React.SetStateAction<number>>
}

const RecoveryPassForm = ({ callback }: RecoveryPassFormParams) => {
   const [showNewPassword, setShowNewPassword] = useState(false)
   const [showConfirmPassword, setShowConfirmPassword] = useState(false)
   const user = useAppSelector(selectUser, shallowEqual) as IUser
   const { token } = useParams()
   const { t } = useTranslation()

   const validationSchema = Yup.object().shape({
      newPass: Yup.string()
         .min(8, t('validation_messages.passLength', { count: 8 }))
         .required(t('validation_messages.required'))
         .matches(/[A-Z]/, t('validation_messages.passUppercase'))
         .matches(/[^a-zA-Z0-9]/, t('validation_messages.passSpecialChar')),
      confirmNewPass: Yup.string()
         .oneOf(
            [Yup.ref('newPass'), undefined],
            t('validation_messages.matchPass')
         )
         .required(t('validation_messages.confirmPass')),
   })

   const {
      handleSubmit,
      formState: { errors, isValid, isDirty, isLoading },
      control,
      setError,
   } = useForm({
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
   })

   const disabledSubmitBtn = !isValid || !isDirty || isLoading

   const onSubmit: SubmitHandler<IFormInput> = async ({
      newPass,
   }: IFormInput) => {
      if (newPass && token) {
         resetPass(token, newPass)
            .then((res) => {
               AnalyticsService.track(UserEvents.RECOVERY_PASS_SUBMITTED, {})
               AnalyticsService.identify(user?.id)
               callback(2)
            })
            .catch((e) => {
               setError('newPass', {
                  type: 'manual',
               })
               setError('confirmNewPass', {
                  type: 'manual',
                  message: e?.data?.message,
               })
            })
      }
   }

   const handleShowPassword = () => setShowNewPassword((show) => !show)
   const handleShowConfirmPassword = () =>
      setShowConfirmPassword((show) => !show)

   return (
      <RecoveryPassFormContainer>
         <Typography variant="h1">{t('pages.recovery_pass.title')}</Typography>
         <Typography variant="body2">
            {t('pages.recovery_pass.subtitle')}
         </Typography>
         <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
               name="newPass"
               control={control}
               defaultValue=""
               render={({ field }) => (
                  <FormInput
                     label={t('pages.recovery_pass.new_pass')}
                     variant="outlined"
                     type={showNewPassword ? 'text' : 'password'}
                     {...field}
                     error={!!errors?.newPass}
                     helperText={
                        errors?.newPass ? errors?.newPass?.message : ''
                     }
                     InputProps={{
                        endAdornment: (
                           <InputPasswordAdornment
                              onClick={handleShowPassword}
                              isOpen={showNewPassword}
                           />
                        ),
                     }}
                  />
               )}
            />
            <Controller
               name="confirmNewPass"
               control={control}
               defaultValue=""
               render={({ field }) => (
                  <FormInput
                     label={t('pages.recovery_pass.confirm_pass')}
                     variant="outlined"
                     type={showConfirmPassword ? 'text' : 'password'}
                     {...field}
                     error={!!errors?.confirmNewPass}
                     helperText={
                        errors?.confirmNewPass
                           ? errors?.confirmNewPass?.message
                           : ''
                     }
                     InputProps={{
                        endAdornment: (
                           <InputPasswordAdornment
                              onClick={handleShowConfirmPassword}
                              isOpen={showConfirmPassword}
                           />
                        ),
                     }}
                  />
               )}
            />
            <SubmitBtn type="submit" disabled={disabledSubmitBtn}>
               <Typography>{t('pages.recovery_pass.btn')}</Typography>
            </SubmitBtn>
         </form>
      </RecoveryPassFormContainer>
   )
}

export default RecoveryPassForm
