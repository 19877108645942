import RecoveryPass from '../../pages/RecoveryPass'
import ForgotPass from '../../pages/ForgotPass'
import SignUp from '../../pages/SignUp/SignUp'
import ContactUs from '../../pages/ContactUs'
import Login from '../../pages/Login/Login'
import Activate from '../../pages/Activate'
import Privacy from '../../pages/Privacy'
import { IRoute, ROUTES } from './IRoute'
import Terms from '../../pages/Terms'
import Home from '../../pages/Home'

const routes: IRoute[] = [
   {
      path: ROUTES.HOME,
      component: Home,
   },
   {
      path: ROUTES.LOGIN,
      component: Login,
   },
   {
      path: ROUTES.SIGNUP,
      component: SignUp,
   },
   {
      path: ROUTES.FORGOT_PASS,
      component: ForgotPass,
   },
   {
      path: ROUTES.RECOVERY_PASS,
      component: RecoveryPass,
   },
   {
      path: ROUTES.ACTIVATE,
      component: Activate,
   },
   {
      path: ROUTES.TERMS,
      component: Terms,
   },
   {
      path: ROUTES.PRIVACY,
      component: Privacy,
   },
   {
      path: ROUTES.CONTACT_US,
      component: ContactUs,
   },
]

export default routes
