import {
   ContactUsContainer,
   ContactUsHeaderContainer,
   ContactUsScrollContainer,
} from './styled'
import smallLogo from '../../assets/img/logo/logo_small_Be.svg'
import { ROUTES } from '../../models/router/IRoute'
import logo from '../../assets/img/logo/logo.svg'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { device } from '../../theme'
import React from 'react'

const ContactUs = () => {
   const isMobile = useMediaQuery(() => device.xs.max)
   const navigate = useNavigate()

   const handleClick = () => {
      navigate(ROUTES.HOME)
   }

   return (
      <ContactUsContainer>
         <ContactUsHeaderContainer>
            <img
               src={isMobile ? smallLogo : logo}
               alt="be-closer-logo"
               onClick={handleClick}
            />
         </ContactUsHeaderContainer>
         <ContactUsScrollContainer>
            <h2>Contact Us</h2>

            <p>
               We are always happy to help you with any questions or concerns
               you may.
            </p>

            <ul>
               <li>
                  Via Email:{' '}
                  <a href="mailto:support@becloser.me">support@becloser.me</a>
               </li>
            </ul>
         </ContactUsScrollContainer>
      </ContactUsContainer>
   )
}

export default ContactUs
