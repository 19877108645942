const translation = {
   common: {
      cancel: 'batal',
      delete: 'hapus',
      create: 'buat',
      check: 'periksa',
      send: 'kirim',
      update: 'perbarui',
      title: 'judul',
      subtitle: 'subjudul',
      search: 'Cari',
      name: 'Nama',
      description: 'Deskripsi',
      description_optional: 'Deskripsi (opsional)',
      email: 'Email',
      password: 'Kata Sandi',
   },
   search: {
      empty: 'Tidak ada pesan yang ditemukan',
      found: 'pesan ditemukan',
   },
   context_menu: {
      settings: 'Pengaturan',
      report_bug: 'Laporkan bug',
      logout: 'Keluar',
      delete_chat: 'Hapus obrolan',
      delete: 'Hapus',
      copy: 'Salin',
   },
   pages: {
      signup: {
         title: 'Buat akun',
         subtitle:
            'Dengan mendaftar atau masuk, Anda menyetujui Ketentuan Penggunaan dan Kebijakan Privasi kami',
         btn: 'Buat akun',
         haveAnAccount: 'Sudah punya akun?',
         login: 'Masuk',
      },
      login: {
         title: 'Masuk',
         forgotPass: 'Lupa kata sandi?',
         btn: 'Masuk',
         howTo: 'Bagaimana Menjadi > Lebih Dekat?',
         createAccount: 'Buat akun',
      },
      forgot_pass: {
         title: 'Atur ulang kata sandi',
         subtitle:
            'Masukkan email Anda, dan kami akan mengirimkan tautan untuk mengatur ulang kata sandi',
         btn: 'Kirim tautan',
         title2: 'Instruksi Terkirim',
         subtitle2:
            "Email yang berisi tautan untuk mengatur ulang kata sandi telah dikirim ke alamat email Anda. Selain memeriksa folder 'Inbox', harap periksa juga folder 'Spam' di kotak masuk email Anda. Email tersebut mungkin diarahkan ke sana.",
         backBtn: 'Kembali ke beranda',
      },
      recovery_pass: {
         title: 'Atur ulang kata sandi',
         subtitle:
            'Hampir selesai! Buat kata sandi baru yang kuat dan kembali beraksi!',
         new_pass: 'Kata sandi baru',
         confirm_pass: 'Konfirmasi kata sandi',
         btn: 'Simpan Kata Sandi Baru',
      },
      unavailable: {
         title: 'Halaman ini tidak tersedia',
         subtitle: 'Maaf, Anda tidak dapat mengakses ini',
         btn: 'Beranda',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Pilih lawan bicara Anda',
         subtitle:
            'Silakan masukkan nama depan dan belakang atau nama samaran dari orang yang ingin Anda komunikasikan',
         description_hint:
            'Untuk hasil pencarian yang optimal, silakan berikan deskripsi',
         back_label_link: 'Tidak menemukan lawan bicara Anda?',
      },
      report_bug: {
         title: 'Laporkan bug',
         subtitle: 'Silakan rinci masalah Anda atau berikan umpan balik.',
         title_placeholder: 'Ketik subjek utama laporan Anda…',
         subtitle_placeholder: 'Masukkan gambaran detail dari isi laporan…',
      },
      delete_conversation: {
         title: 'Hapus Percakapan',
         subtitle: 'Anda yakin ingin menghapus percakapan ini?',
      },
      delete_message: {
         title: 'Hapus Pesan',
         subtitle: 'Anda yakin ingin menghapus pesan ini?',
      },
   },
   conversation: {
      placeholder: 'Tulis pesan…',
   },
   settings: {
      title: 'Pengaturan',
      privacy_and_security: 'Privasi dan Keamanan',
      language: 'Bahasa',
      report_bug: 'Laporkan bug',
      terms_of_use: 'Ketentuan Penggunaan',
   },
   profile: {
      title: 'Edit Profil',
      bio: 'Bio',
      bio_optional: 'Bio (opsional)',
      bio_hint:
         'Dalam bio Anda, beri tahu apa yang ingin Anda bagikan kepada mitra obrolan Anda. Ini akan membantu dalam memberikan respons yang lebih disesuaikan dan lebih tepat untuk kebutuhan Anda.',
   },
   validation_messages: {
      required: 'Bidang ini diperlukan.',
      titleMaxLength: 'Judul maksimal {{count}} karakter.',
      invalidEmailFormat: 'Format email tidak valid.',
      passLength: 'Kata sandi harus setidaknya {{count}} karakter',
      passUppercase: 'Kata sandi harus mengandung setidaknya satu huruf besar',
      passSpecialChar:
         'Kata sandi harus mengandung setidaknya satu karakter khusus',
      emailAlreadyRegistered: 'Email ini sudah terdaftar.',
      failedLogin: 'Gagal masuk. Silakan periksa kredensial Anda.',
      matchPass: 'Kata sandi harus cocok',
      confirmPass: 'Konfirmasi Kata Sandi diperlukan',
   },
}

export default translation
