const ACCESS_TOKEN = 'token'

export const isAuthenticated = () => getAccessToken() !== null

export const setAccessToken = (token: string) =>
   localStorage.setItem(ACCESS_TOKEN, token)

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN)

export const clearTokens = () => {
   localStorage.removeItem(ACCESS_TOKEN)
}
