import { AuthResponse } from '../../models/response/authResponse'
import { CodeResponse } from '@react-oauth/google'
import axios, { AxiosResponse } from 'axios'
import api from '../apiService'

const API_URL = process.env.REACT_APP_API_URL

export const registration = async (
   email: string,
   password: string
): Promise<AxiosResponse<AuthResponse>> => {
   try {
      return await api.post<AuthResponse>('/user/registration', {
         email,
         password,
      })
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const login = async (
   email: string,
   password: string
): Promise<AxiosResponse<AuthResponse>> => {
   try {
      return await api.post<AuthResponse>('/user/login', {
         email,
         password,
      })
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const loginGoogle = async (
   payload: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>
): Promise<AxiosResponse<AuthResponse>> => {
   try {
      return await api.post<AuthResponse>('/user/login/google', {
         payload,
      })
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const logout = async (): Promise<void> => {
   try {
      return await api.post('/user/logout')
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const checkAuth = async (): Promise<AxiosResponse<AuthResponse>> => {
   try {
      return await axios.get<AuthResponse>(`${API_URL}/user/refresh`, {
         withCredentials: true,
      })
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const sendReport = async (description: string, title: string) => {
   try {
      return api.post('/report', {
         title,
         description,
      })
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const activateAccount = async (link: string): Promise<AxiosResponse> => {
   try {
      return await axios.get<AuthResponse>(`${API_URL}/user/activate/${link}`)
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const resetPass = async (token: string, newPassword: string) => {
   try {
      return api.post('/user/reset', {
         token,
         newPassword,
      })
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const checkMail = async (email: string): Promise<AxiosResponse> => {
   try {
      return await axios.get<AuthResponse>(`${API_URL}/user/recovery`, {
         params: {
            email,
         },
      })
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

export const deleteUser = async (): Promise<AxiosResponse> => {
   try {
      return await api.delete(`${API_URL}/user`)
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}
