import {
   checkAuth,
   deleteUser,
   login,
   loginGoogle,
   logout,
   registration,
} from '../../services/api/userApi'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearTokens, setAccessToken } from '../../services/authService'
import { AuthResponse } from '../../models/response/authResponse'
import { IUserState } from '../../models/storeModels/IUser'
import { IGlobalState } from '../../models/storeModels'
import { CodeResponse } from '@react-oauth/google'

const initialState: IUserState = {
   isDemo: false,
   isAuth: false,
   user: null,
   loading: false,
   error: null,
}

export const registerUser = createAsyncThunk<
   AuthResponse,
   { email: string; password: string },
   { rejectValue: string }
>('user/register', async (payload, { rejectWithValue }) => {
   try {
      const response = await registration(payload.email, payload.password)
      setAccessToken(response.data.accessToken)
      return response.data
   } catch (error: any) {
      return rejectWithValue(error.response?.data)
   }
})

export const loginUser = createAsyncThunk<
   AuthResponse,
   { email: string; password: string },
   { rejectValue: string }
>('user/login', async (payload, { rejectWithValue }) => {
   try {
      const response = await login(payload.email, payload.password)
      setAccessToken(response.data.accessToken)
      return response.data
   } catch (error: any) {
      return rejectWithValue(error.response?.data)
   }
})
export const loginGoogleUser = createAsyncThunk<
   AuthResponse,
   Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>,
   { rejectValue: string }
>('user/login-google', async (codeResponse, { rejectWithValue }) => {
   try {
      const response = await loginGoogle(codeResponse)
      setAccessToken(response.data.accessToken)
      return response.data
   } catch (error: any) {
      return rejectWithValue(error.response?.data)
   }
})

export const logoutUser = createAsyncThunk('user/logout', async () => {
   await logout()
   clearTokens()
})

export const deleteAccount = createAsyncThunk('user/delete', async () => {
   await deleteUser()
   clearTokens()
})

export const checkAuthUser = createAsyncThunk(
   'user/checkAuth',
   async (_, { rejectWithValue }) => {
      try {
         const response = await checkAuth()
         setAccessToken(response.data.accessToken)
         return response.data
      } catch (error: any) {
         return rejectWithValue(error.response?.data)
      }
   }
)

const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      toggleIsDemo: (state, action: PayloadAction<boolean>) => {
         state.isDemo = action.payload
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(checkAuthUser.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(checkAuthUser.fulfilled, (state, action) => {
            state.isAuth = true
            state.user = action.payload.user
            state.loading = false
            state.error = null
         })
         .addCase(checkAuthUser.rejected, (state, action) => {
            state.isAuth = false
            state.user = null
            state.loading = false
            // state.error = action.payload
         })
         .addCase(registerUser.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(registerUser.fulfilled, (state, action) => {
            state.isAuth = true
            state.user = action.payload.user
            state.loading = false
            state.error = null
         })
         .addCase(registerUser.rejected, (state, action: any) => {
            state.loading = false
            state.error = action.payload
         })
         .addCase(loginUser.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(loginUser.fulfilled, (state, action) => {
            state.isAuth = true
            state.user = action.payload.user
            state.loading = false
            state.error = null
         })
         .addCase(loginUser.rejected, (state, action: any) => {
            state.loading = false
            state.error = action.payload
         })
         .addCase(loginGoogleUser.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(loginGoogleUser.fulfilled, (state, action) => {
            state.isAuth = true
            state.user = action.payload.user
            state.loading = false
            state.error = null
         })
         .addCase(loginGoogleUser.rejected, (state, action: any) => {
            state.loading = false
            state.error = action.payload
         })
         .addCase(logoutUser.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(logoutUser.fulfilled, (state) => {
            state.isAuth = false
            state.user = null
            state.loading = false
            state.error = null
         })
   },
})

export const { toggleIsDemo } = userSlice.actions

export const selectIsDemo = (state: IGlobalState) => state.user.isDemo
export const selectIsAuth = (state: IGlobalState) => state.user.isAuth
export const selectUser = (state: IGlobalState) => state.user.user
export const selectUserId = (state: IGlobalState) => state.user.user?.id
export const selectLoading = (state: IGlobalState) => state.user.loading
export const selectError = (state: IGlobalState) => state.user.error

export default userSlice.reducer
