import {
   AnyAction,
   CombinedState,
   combineReducers,
   configureStore,
} from '@reduxjs/toolkit'
import conversationSlice from './slices/conversationSlice'
import { IGlobalState } from '../models/storeModels'
import settingsSlice from './slices/settingsSlice'
import messageSlice from './slices/messageSlice'
import profileSlice from './slices/profileSlice'
import searchSlice from './slices/searchSlice'
import modalSlice from './slices/modalSlice'
import userSlice from './slices/userSlice'
import passSlice from './slices/passSlice'

const reducers = combineReducers({
   modal: modalSlice,
   conversation: conversationSlice,
   message: messageSlice,
   user: userSlice,
   password: passSlice,
   search: searchSlice,
   settings: settingsSlice,
   profile: profileSlice,
})

const rootReducer = (
   state: CombinedState<IGlobalState> | undefined,
   action: AnyAction
): CombinedState<IGlobalState> => {
   if (action.type === 'user/logout') {
      state = undefined
   }
   return reducers(state, action)
}

const store = configureStore({
   reducer: rootReducer,
   devTools: true,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export default store
