import { Box, Link } from '@mui/material'
import { styled } from '@mui/system'
import { device } from '../../theme'

export const CookiesBlockContainer = styled(Box)<{ hidden: boolean }>`
   width: 300px;
   box-sizing: border-box;
   position: absolute;
   top: calc(100vh - 76px);
   left: 50%;
   transform: translateX(-50%);
   display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
   gap: 16px;
   padding: 14px 24px;
   border-radius: 24px;
   background-color: ${({ theme }) => theme.palette.gray.primary};

   span {
      text-decoration: underline;
      cursor: pointer;
   }
   p:nth-of-type(2) {
      text-decoration: underline;
      cursor: pointer;
   }

   @media ${device.xs.max} {
      top: calc(100vh - 160px);
      width: calc(100% - 40px);
      justify-content: space-between;
   }
`

export const CookiesLink = styled(Link)`
   color: inherit;
   text-decoration-color: inherit;
`
