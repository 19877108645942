import { SignUpBtn } from '../../components/common/Buttons'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const UnavailableContainer = styled(Box)`
   background: ${({ theme }) => theme.palette.main.background};
   box-shadow: 0 20px 40px 0 rgba(49, 34, 46, 0.2);
   padding: 24px 0 40px 0;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 100vh;
   width: 100%;
`

export const UnavailableContent = styled(Box)`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100%;
`

export const UnavailableContentSubtitle = styled(Typography)`
   color: ${({ theme }) => theme.palette.white.primary};
   margin: 24px 0 32px 0;
`

export const HomeBtn = styled(SignUpBtn)`
   width: 100px;
   color: ${({ theme }) => theme.palette.white.primary};
`
