export const LANGUAGES = [
   { label: 'English', code: 'en', native: 'English', rtl: false },
   { label: 'Arabic', code: 'ar', native: 'العربية', rtl: true },
   { label: 'Chinese', code: 'zh', native: '中文', rtl: false },
   { label: 'Czech', code: 'cs', native: 'Čeština', rtl: false },
   { label: 'Dutch', code: 'nl', native: 'Nederlands', rtl: false },
   { label: 'French', code: 'fr', native: 'Français', rtl: false },
   { label: 'German', code: 'de', native: 'Deutsch', rtl: false },
   { label: 'Indonesian', code: 'id', native: 'Bahasa Indonesia', rtl: false },
   { label: 'Italian', code: 'it', native: 'Italiano', rtl: false },
   { label: 'Japanese', code: 'ja', native: '日本語', rtl: false },
   { label: 'Korean', code: 'ko', native: '한국어', rtl: false },
   { label: 'Persian', code: 'fa', native: 'فارسی', rtl: true },
   { label: 'Polish', code: 'pl', native: 'Polski', rtl: false },
   { label: 'Portuguese', code: 'pt', native: 'Português', rtl: false },
   { label: 'Russian', code: 'ru', native: 'Русский', rtl: false },
   { label: 'Spanish', code: 'es', native: 'Español', rtl: false },
   { label: 'Turkish', code: 'tr', native: 'Türkçe', rtl: false },
   { label: 'Ukrainian', code: 'uk', native: 'Українська', rtl: false },
]

export type ThemeType = 'Dark' | 'Light'

export const THEMES = {
   DARK: 'Dark' as ThemeType,
   LIGHT: 'Light' as ThemeType,
}

export const HERO_LABELS: string[] = [
   'Influencers',
   'Artists',
   'Actors',
   'Musicians',
   'YouTubers',
   'Bloggers',
   'Entrepreneurs',
   'Athletes',
   'Authors',
   'Fashion Icons',
   'Models',
   'Chefs',
   'Politicians',
   'Activists',
   'Scientists',
   'Tech Innovators',
   'Business Leaders',
   'Professional Gamers',
   'Podcasters',
   'Dancers',
   'Photographers',
   'Film Directors',
   'Visual Artists',
   'Comedians',
   'Social Media Stars',
   'Journalists',
   'Radio Personalities',
   'Motivational Speakers',
   'Illustrators',
   'Graphic Designers',
   'DJs',
   'Vloggers',
   'Stage Performers',
   'Screenwriters',
   'Cinematographers',
   'Fashion Designers',
   'Makeup Artists',
   'Public Speakers',
   'Environmentalists',
   'Historical Figures',
   'Fictional Characters',
   'Philanthropists',
   'Anyone from your idols',
]

export const LOGIN_TYPES = {
   GOOGLE: 'GOOGLE' as string,
   APPLE_ID: 'APPLE_ID' as string,
   BY_MAIL: 'BY_MAIL' as string,
}
