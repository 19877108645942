import useAnalyticsPageView from '../../hooks/Analitycs/useAnalyticsPageView'
import { ForgotPassHeader, ForgotPassWrapper } from './styles'
import HeaderLogo from '../../components/common/HeaderLogo'
import ForgotPassForm from './components/ForgotPassForm'
import ForgotPassSent from './components/ForgotPassSent'
import { PageNames } from '../../models/analitycs'
import React, { useState } from 'react'

const ForgotPass: React.FC = () => {
   const [currStep, setCurrStep] = useState<number>(1)
   useAnalyticsPageView(PageNames.FORGOT_PASS)

   const getRenderStep = (step: number) => {
      const steps: Record<number, React.ReactElement> = {
         1: <ForgotPassForm callback={setCurrStep} />,
         2: <ForgotPassSent />,
      }

      return steps[step] || <></>
   }
   return (
      <ForgotPassWrapper>
         <ForgotPassHeader>
            <HeaderLogo pageName={PageNames.FORGOT_PASS} />
         </ForgotPassHeader>
         {getRenderStep(currStep)}
      </ForgotPassWrapper>
   )
}

export default ForgotPass
