const translation = {
   common: {
      cancel: 'cancelar',
      delete: 'excluir',
      create: 'criar',
      check: 'verificar',
      send: 'enviar',
      update: 'atualizar',
      title: 'título',
      subtitle: 'subtítulo',
      search: 'Pesquisar',
      name: 'Nome',
      description: 'Descrição',
      description_optional: 'Descrição (opcional)',
      email: 'Email',
      password: 'Senha',
   },
   search: {
      empty: 'Nenhuma mensagem encontrada',
      found: 'mensagens encontradas',
   },
   context_menu: {
      settings: 'Configurações',
      report_bug: 'Relatar um erro',
      logout: 'Sair',
      delete_chat: 'Excluir chat',
      delete: 'Excluir',
      copy: 'Copiar',
   },
   pages: {
      signup: {
         title: 'Crie uma conta',
         subtitle:
            'Ao se registrar ou fazer login, você concorda com nossos Termos de Uso e Política de Privacidade',
         btn: 'Criar uma conta',
         haveAnAccount: 'Já tem uma conta?',
         login: 'Entrar',
      },
      login: {
         title: 'Entrar',
         forgotPass: 'Esqueceu sua senha?',
         btn: 'Entrar',
         howTo: 'Como ficar mais próximo?',
         createAccount: 'Criar uma conta',
      },
      forgot_pass: {
         title: 'Redefinir senha',
         subtitle:
            'Insira seu email e nós enviaremos um link para redefinir a senha',
         btn: 'Enviar link',
         title2: 'Instruções enviadas',
         subtitle2:
            "Um email com um link para definir uma nova senha foi enviado para o seu endereço de email. Além de verificar sua pasta 'Caixa de Entrada', verifique também a pasta 'Spam'. O email pode ter sido direcionado para lá.",
         backBtn: 'Voltar para a página inicial',
      },
      recovery_pass: {
         title: 'Redefinir senha',
         subtitle: 'Quase lá! Crie uma nova senha forte e volte à ação!',
         new_pass: 'Nova senha',
         confirm_pass: 'Confirmar senha',
         btn: 'Salvar nova senha',
      },
      unavailable: {
         title: 'Esta página não está disponível',
         subtitle: 'Desculpe, você não pode acessar isto',
         btn: 'Início',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Escolha seu interlocutor',
         subtitle:
            'Por favor, insira o primeiro e último nome ou apelido da pessoa com quem você deseja se comunicar',
         description_hint:
            'Para obter melhores resultados na pesquisa, forneça uma descrição',
         back_label_link: 'Não encontrou seu interlocutor?',
      },
      report_bug: {
         title: 'Relatar um erro',
         subtitle: 'Por favor, detalhe seu problema ou forneça feedback.',
         title_placeholder: 'Digite o assunto principal do seu relatório…',
         subtitle_placeholder:
            'Insira uma visão detalhada do conteúdo do relatório…',
      },
      delete_conversation: {
         title: 'Excluir conversa',
         subtitle: 'Tem certeza de que deseja excluir esta conversa?',
      },
      delete_message: {
         title: 'Excluir mensagem',
         subtitle: 'Tem certeza de que deseja excluir esta mensagem?',
      },
   },
   conversation: {
      placeholder: 'Escreva uma mensagem…',
   },
   settings: {
      title: 'Configurações',
      privacy_and_security: 'Privacidade e Segurança',
      language: 'Idioma',
      report_bug: 'Relatar um erro',
      terms_of_use: 'Termos de uso',
   },
   profile: {
      title: 'Editar perfil',
      bio: 'Bio',
      bio_optional: 'Bio (opcional)',
      bio_hint:
         'Em sua bio, compartilhe o que você gostaria que seu parceiro de conversa soubesse sobre você. Isso ajudará a receber respostas melhor adaptadas às suas necessidades.',
   },
   validation_messages: {
      required: 'Este campo é obrigatório.',
      titleMaxLength: 'O título pode ter no máximo {{count}} caracteres.',
      invalidEmailFormat: 'Formato de email inválido.',
      passLength: 'A senha deve ter pelo menos {{count}} caracteres',
      passUppercase: 'A senha deve conter pelo menos uma letra maiúscula',
      passSpecialChar: 'A senha deve conter pelo menos um caractere especial',
      emailAlreadyRegistered: 'Este email já está registrado.',
      failedLogin: 'Falha ao entrar. Verifique suas credenciais.',
      matchPass: 'As senhas devem ser iguais',
      confirmPass: 'A confirmação da senha é obrigatória',
   },
}

export default translation
