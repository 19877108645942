import api from '../apiService'

export const generateAIResponse = async (
   conversationId: string,
   text: string
) => {
   return api.post('/interlocutor', {
      text,
      conversation_id: conversationId,
   })
}
