import en from '../resources/en'
import ar from '../resources/ar'
import zh from '../resources/zh'
import cs from '../resources/cs'
import uk from '../resources/uk'
import nl from '../resources/nl'
import fr from '../resources/fr'
import de from '../resources/de'
import id from '../resources/id'
import it from '../resources/it'
import ja from '../resources/ja'
import ko from '../resources/ko'
import fa from '../resources/fa'
import pl from '../resources/pl'
import pt from '../resources/pt'
import ru from '../resources/ru'
import es from '../resources/es'
import tr from '../resources/tr'

const resources = {
   en: {
      translation: en,
   },
   ar: {
      translation: ar,
   },
   zh: {
      translation: zh,
   },
   cs: {
      translation: cs,
   },
   nl: {
      translation: nl,
   },
   fr: {
      translation: fr,
   },
   de: {
      translation: de,
   },
   id: {
      translation: id,
   },
   it: {
      translation: it,
   },
   ja: {
      translation: ja,
   },
   ko: {
      translation: ko,
   },
   fa: {
      translation: fa,
   },
   pl: {
      translation: pl,
   },
   pt: {
      translation: pt,
   },
   ru: {
      translation: ru,
   },
   es: {
      translation: es,
   },
   tr: {
      translation: tr,
   },
   uk: {
      translation: uk,
   },
}

export default resources
