import {
   IAddMessageParams,
   IRemoveMessageParams,
   IRemoveMessageResponse,
} from '../../models/response/messageResponse'
import {
   createAsyncThunk,
   createSelector,
   createSlice,
   PayloadAction,
} from '@reduxjs/toolkit'
import {
   createMessage,
   deleteMessage,
   getAllMessages,
} from '../../services/api/messageApi'
import { IInterlocutorParams } from '../../models/storeModels/IInterlocutor'
import { IMessage, IMessageState } from '../../models/storeModels/IMessage'
import { generateAIResponse } from '../../services/api/interlocutorApi'
import { IGlobalState } from '../../models/storeModels'

const initialState: IMessageState = {
   messages: [],
   loading: false,
   interlocutorLoading: false,
   isSearchBar: false,
   error: null,
}

export const fetchMessages = createAsyncThunk(
   'messages/fetchMessages',
   async (conversationId: string, { rejectWithValue }) => {
      try {
         const response = await getAllMessages(conversationId)
         return response.data as IMessage[]
      } catch (error) {
         return rejectWithValue('Failed to fetch messages')
      }
   }
)

export const addMessage = createAsyncThunk(
   'messages/addMessage',
   async ({ conversationId, text }: IAddMessageParams) => {
      try {
         const response = await createMessage(conversationId, text)
         return response.data as IMessage
      } catch (error) {
         throw new Error('Failed to add message')
      }
   }
)

export const getInterlocutorResponse = createAsyncThunk(
   'messages/getInterlocutorResponse',
   async ({ conversationId, text }: IInterlocutorParams) => {
      try {
         const response = await generateAIResponse(conversationId, text)
         return response.data as IMessage
      } catch (error) {
         throw new Error('Failed to get interlocutor response')
      }
   }
)

export const removeMessage = createAsyncThunk<
   IRemoveMessageParams,
   IRemoveMessageResponse
>('messages/removeMessage', async ({ conversationId, messageId }) => {
   try {
      await deleteMessage(conversationId, messageId)
      return { messageId, message: 'Message removed successfully' }
   } catch (error) {
      throw new Error('Failed to remove message')
   }
})

const messageSlice = createSlice({
   name: 'messages',
   initialState,
   reducers: {
      setMessages: (state, action: PayloadAction<IMessage[]>) => {
         state.messages = action.payload
      },
      setLoading: (state, action: PayloadAction<boolean>) => {
         state.loading = action.payload
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchMessages.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(
            fetchMessages.fulfilled,
            (state, action: PayloadAction<IMessage[]>) => {
               state.loading = false
               state.error = null
               state.messages = action.payload
            }
         )
         .addCase(
            fetchMessages.rejected,
            (state, action: PayloadAction<any>) => {
               state.loading = false
               state.error = action.payload
            }
         )
         .addCase(addMessage.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(
            addMessage.fulfilled,
            (state, action: PayloadAction<IMessage>) => {
               state.loading = false
               state.error = null
               state.messages.push(action.payload)
            }
         )
         .addCase(addMessage.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
         })
         .addCase(removeMessage.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(
            removeMessage.fulfilled,
            (state, action: PayloadAction<IRemoveMessageParams>) => {
               state.loading = false
               state.error = null
               state.messages = state.messages.filter(
                  (message) => message.id !== action.payload.messageId
               )
            }
         )
         .addCase(
            removeMessage.rejected,
            (state, action: PayloadAction<any>) => {
               state.loading = false
               state.error = action.payload
            }
         )
         .addCase(getInterlocutorResponse.pending, (state) => {
            state.interlocutorLoading = true
            state.error = null
         })
         .addCase(
            getInterlocutorResponse.fulfilled,
            (state, action: PayloadAction<IMessage>) => {
               state.interlocutorLoading = false
               state.error = null
               state.messages.push(action.payload)
            }
         )
         .addCase(
            getInterlocutorResponse.rejected,
            (state, action: PayloadAction<any>) => {
               state.interlocutorLoading = false
               state.error = action.payload
            }
         )
   },
})

export const { setMessages, setLoading } = messageSlice.actions

export const selectMessages = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.message.messages
)
export const selectLoading = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.message.loading
)
export const selectInterlocutorLoading = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.message.interlocutorLoading
)

export default messageSlice.reducer
