const translation = {
   common: {
      cancel: 'annuler',
      delete: 'supprimer',
      create: 'créer',
      check: 'vérifier',
      send: 'envoyer',
      update: 'mettre à jour',
      title: 'titre',
      subtitle: 'sous-titre',
      search: 'Recherche',
      name: 'Nom',
      description: 'Description',
      description_optional: 'Description (facultatif)',
      email: 'Email',
      password: 'Mot de passe',
   },
   search: {
      empty: 'Aucun message trouvé',
      found: 'messages trouvés',
   },
   context_menu: {
      settings: 'Paramètres',
      report_bug: 'Signaler un bug',
      logout: 'Se déconnecter',
      delete_chat: 'Supprimer la conversation',
      delete: 'Supprimer',
      copy: 'Copier',
   },
   pages: {
      signup: {
         title: 'Créer un compte',
         subtitle:
            "En vous inscrivant ou en vous connectant, vous acceptez nos Conditions d'utilisation et notre Politique de confidentialité",
         btn: 'Créer un compte',
         haveAnAccount: 'Vous avez un compte?',
         login: 'Se connecter',
      },
      login: {
         title: 'Se connecter',
         forgotPass: 'Mot de passe oublié?',
         btn: 'Se connecter',
         howTo: 'Comment être > Plus proche?',
         createAccount: 'Créer un compte',
      },
      forgot_pass: {
         title: 'Réinitialiser le mot de passe',
         subtitle:
            'Entrez votre email et nous vous enverrons un lien pour réinitialiser votre mot de passe',
         btn: 'Envoyer le lien',
         title2: 'Instructions envoyées',
         subtitle2:
            "Un email contenant un lien pour définir un nouveau mot de passe a été envoyé à votre adresse email. En plus de vérifier votre dossier 'Boîte de réception', veuillez également vérifier le dossier 'Spam' de votre boîte de réception. L'email pourrait y avoir été dirigé.",
         backBtn: "Retour à l'accueil",
      },
      recovery_pass: {
         title: 'Réinitialiser le mot de passe',
         subtitle:
            "Presque là! Créez un nouveau mot de passe solide et replongez-vous dans l'action!",
         new_pass: 'Nouveau mot de passe',
         confirm_pass: 'Confirmer le mot de passe',
         btn: 'Sauvegarder le nouveau mot de passe',
      },
      unavailable: {
         title: "Cette page n'est pas disponible",
         subtitle: 'Désolé, vous ne pouvez pas accéder à ceci',
         btn: 'Accueil',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Choisissez votre interlocuteur',
         subtitle:
            'Veuillez entrer le prénom et le nom ou le surnom de la personne avec laquelle vous souhaitez communiquer',
         description_hint:
            'Pour des résultats de recherche optimaux, veuillez fournir une description',
         back_label_link: "Vous n'avez pas trouvé votre interlocuteur?",
      },
      report_bug: {
         title: 'Signaler un bug',
         subtitle:
            'Veuillez détailler votre problème ou fournir des commentaires.',
         title_placeholder: 'Indiquez le sujet principal de votre rapport…',
         subtitle_placeholder:
            'Entrez un aperçu détaillé du contenu du rapport…',
      },
      delete_conversation: {
         title: 'Supprimer la conversation',
         subtitle: 'Êtes-vous sûr de vouloir supprimer cette conversation?',
      },
      delete_message: {
         title: 'Supprimer le message',
         subtitle: 'Êtes-vous sûr de vouloir supprimer ce message?',
      },
   },
   conversation: {
      placeholder: 'Écrire un message…',
   },
   settings: {
      title: 'Paramètres',
      privacy_and_security: 'Confidentialité et sécurité',
      language: 'Langue',
      report_bug: 'Signaler un bug',
      terms_of_use: "Conditions d'utilisation",
   },
   profile: {
      title: 'Modifier le profil',
      bio: 'Bio',
      bio_optional: 'Bio (facultatif)',
      bio_hint:
         'Dans votre bio, partagez ce que vous aimeriez que votre interlocuteur sache de vous. Cela aidera à obtenir des réponses mieux adaptées et plus précises à vos besoins.',
   },
   validation_messages: {
      required: 'Ce champ est obligatoire.',
      titleMaxLength: 'Le titre peut contenir au maximum {{count}} caractères.',
      invalidEmailFormat: "Format d'email invalide.",
      passLength: 'Le mot de passe doit contenir au moins {{count}} caractères',
      passUppercase:
         'Le mot de passe doit contenir au moins une lettre majuscule',
      passSpecialChar:
         'Le mot de passe doit contenir au moins un caractère spécial',
      emailAlreadyRegistered: 'Cet email est déjà enregistré.',
      failedLogin: 'Échec de la connexion. Veuillez vérifier vos identifiants.',
      matchPass: 'Les mots de passe doivent correspondre',
      confirmPass: 'La confirmation du mot de passe est requise',
   },
}

export default translation
