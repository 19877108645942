import { styled } from '@mui/system'
import { Box } from '@mui/material'
export const ScrollContainer = styled(Box)`
   height: 100%;
   overflow: auto;
   position: relative;
   box-sizing: content-box;

   ::-webkit-scrollbar {
      width: 6px;
      padding: 0;
      margin: 0;
   }

   ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
   }

   &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(128, 128, 128, 0.3);
      border-radius: 6px;
   }

   ::-webkit-scrollbar-track {
      position: absolute;
      background-color: transparent;
   }
`
