import {
   getProfile,
   updateProfile as update,
} from '../../services/api/profileApi'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { IProfileState, ProfileData } from '../../models/storeModels/IProfile'
import { IGlobalState } from '../../models/storeModels'

const initialState: IProfileState = {
   username: '',
   avatar: '',
   bio: '',
   loading: false,
   error: null,
}

export const fetchProfile = createAsyncThunk(
   'profile/fetchProfile',
   async () => {
      const response = await getProfile()
      return response.data
   }
)

export const updateProfile = createAsyncThunk<
   IProfileState,
   ProfileData,
   { rejectValue: string }
>('profile/updateProfile', async (data: ProfileData, { rejectWithValue }) => {
   try {
      return await update(data)
   } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to update profile')
   }
})

const profileSlice = createSlice({
   name: 'profile',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchProfile.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(fetchProfile.fulfilled, (state, action) => {
            state.loading = false
            state.username = action.payload.username
            state.avatar = action.payload.avatar
            state.bio = action.payload.bio
         })
         .addCase(fetchProfile.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || 'Failed to fetch profile'
         })
         .addCase(updateProfile.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(updateProfile.fulfilled, (state, action) => {
            state.loading = false
            state.username = action.payload.username
            state.avatar = action.payload.avatar
            state.bio = action.payload.bio
         })
         .addCase(updateProfile.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || 'Failed to fetch profile'
         })
   },
})

export const selectProfile = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.profile
)

export default profileSlice.reducer
