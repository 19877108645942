import {
   ConversationLoadingStatus,
   IConversation,
   IConversationState,
   FAILED,
   IDLE,
   LOADING,
   SUCCEEDED,
   IInterlocutorOption,
   IInterlocutorOptionResponse,
} from '../../models/storeModels/IConveration'
import {
   createConversation,
   deleteConversation,
   getAllConversations,
   getInterlocutorsOptions,
} from '../../services/api/conversationApi'
import {
   createAsyncThunk,
   createSelector,
   createSlice,
   PayloadAction,
} from '@reduxjs/toolkit'
import { IGlobalState } from '../../models/storeModels'

const initInterlocutor = {
   name: '',
   description: '',
   id: '',
}

const initialState: IConversationState & ConversationLoadingStatus = {
   options: [],
   selectedInterlocutor: initInterlocutor,
   list: [],
   fetchStatus: IDLE,
   addStatus: IDLE,
   deleteStatus: IDLE,
   error: null,
   selectedConversation: {
      id: '',
      user_id: '',
      interlocutor: { name: '', img: '' },
      createdAt: '',
      updatedAt: '',
      latestMessage: {
         id: '',
         text: '',
         createdAt: '',
         updatedAt: '',
         role: 'system',
      },
   },
   prevConversationId: '',
   backToList: true,
}

export const fetchConversations = createAsyncThunk(
   'conversation/fetchConversations',
   async () => {
      const response = await getAllConversations()
      return response.data
   }
)

export const getConversationsOptions = createAsyncThunk(
   'conversation/getConversationsOptions',
   async (interlocutor: IInterlocutorOptionResponse, { rejectWithValue }) => {
      try {
         const response = await getInterlocutorsOptions(interlocutor)
         return response.data
      } catch (e: any) {
         if (e.status === 400) {
            return rejectWithValue(e.data.message)
         }

         if (e instanceof Error) {
            return rejectWithValue(e.message)
         }
         return rejectWithValue('An unknown error occurred')
      }
   }
)
export const addConversation = createAsyncThunk(
   'conversation/addConversation',
   async (interlocutor: IInterlocutorOption, { rejectWithValue }) => {
      try {
         const response = await createConversation(interlocutor)
         return response.data
      } catch (e: any) {
         if (e.status === 400) {
            return rejectWithValue(e.data.message)
         }

         if (e instanceof Error) {
            return rejectWithValue(e.message)
         }
         return rejectWithValue('An unknown error occurred')
      }
   }
)

export const removeConversation = createAsyncThunk(
   'conversation/removeConversation',
   async (conversationId: string) => {
      const response = await deleteConversation(conversationId)
      return response.data
   }
)

const conversationSlice = createSlice({
   name: 'conversation',
   initialState,
   reducers: {
      removeSelectedInterlocutor: (state, action: PayloadAction<any>) => {
         state.options = action.payload
      },
      removeInterlocutorsOptions: (state, action: PayloadAction<any>) => {
         state.options = action.payload
         state.selectedInterlocutor = initInterlocutor
      },
      selectConversation: (state, action: PayloadAction<IConversation>) => {
         state.selectedConversation = action.payload
      },
      selectInterlocutor: (
         state,
         action: PayloadAction<IInterlocutorOption>
      ) => {
         state.selectedInterlocutor = action.payload
      },
      moveBackToList: (state, action: PayloadAction<boolean>) => {
         state.backToList = action.payload
      },
      setPrevConversationId: (state, action: PayloadAction<string>) => {
         state.prevConversationId = action.payload
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchConversations.pending, (state) => {
            state.fetchStatus = LOADING
         })
         .addCase(
            fetchConversations.fulfilled,
            (state, action: PayloadAction<any>) => {
               state.fetchStatus = SUCCEEDED
               state.list = action.payload
            }
         )
         .addCase(
            fetchConversations.rejected,
            (state, action: PayloadAction<any>) => {
               state.fetchStatus = FAILED
               state.error = action.payload
            }
         )

         .addCase(addConversation.pending, (state) => {
            state.addStatus = LOADING
         })
         .addCase(
            addConversation.fulfilled,
            (state, action: PayloadAction<any>) => {
               state.addStatus = SUCCEEDED
               state.list.push(action.payload)
            }
         )
         .addCase(
            addConversation.rejected,
            (state, action: PayloadAction<any>) => {
               state.addStatus = FAILED
               state.error = action.payload
            }
         )
         .addCase(getConversationsOptions.pending, (state) => {
            state.addStatus = LOADING
         })
         .addCase(
            getConversationsOptions.fulfilled,
            (state, action: PayloadAction<any>) => {
               state.addStatus = SUCCEEDED
               state.options = [...action.payload]
            }
         )
         .addCase(
            getConversationsOptions.rejected,
            (state, action: PayloadAction<any>) => {
               state.addStatus = FAILED
               state.error = action.payload
               state.options = []
            }
         )
         .addCase(removeConversation.pending, (state) => {
            state.deleteStatus = LOADING
         })
         .addCase(
            removeConversation.fulfilled,
            (state, action: PayloadAction<any>) => {
               state.deleteStatus = SUCCEEDED
               const index = state.list.findIndex(
                  (item) => item.id === action.payload
               )
               if (index !== -1) {
                  state.list.splice(index, 1)
               }
            }
         )
         .addCase(
            removeConversation.rejected,
            (state, action: PayloadAction<any>) => {
               state.deleteStatus = FAILED
               state.error = action.payload
            }
         )
   },
})
export const {
   removeSelectedInterlocutor,
   selectInterlocutor,
   selectConversation,
   moveBackToList,
   setPrevConversationId,
   removeInterlocutorsOptions,
} = conversationSlice.actions
export const selectedConversation = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.conversation.selectedConversation
)
export const selectedConversationId = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.conversation.selectedConversation.id
)

export const getPrevConversationId = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.conversation.prevConversationId
)

export const getBackToList = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.conversation.backToList
)

export const selectInterlocutorsOptions = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.conversation.options
)

export const getSelectedInterlocutor = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.conversation.selectedInterlocutor
)

export const getSelectedInterlocutorName = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.conversation.selectedInterlocutor.name
)
export const getSelectedInterlocutorId = createSelector(
   (state: IGlobalState) => state,
   (state: IGlobalState) => state.conversation.selectedInterlocutor.id
)
export default conversationSlice.reducer
