const translation = {
   common: {
      cancel: 'anuluj',
      delete: 'usuń',
      create: 'utwórz',
      check: 'sprawdź',
      send: 'wyślij',
      update: 'aktualizuj',
      title: 'tytuł',
      subtitle: 'podtytuł',
      search: 'Szukaj',
      name: 'Imię',
      description: 'Opis',
      description_optional: 'Opis (opcjonalnie)',
      email: 'Email',
      password: 'Hasło',
   },
   search: {
      empty: 'Nie znaleziono wiadomości',
      found: 'znaleziono wiadomości',
   },
   context_menu: {
      settings: 'Ustawienia',
      report_bug: 'Zgłoś błąd',
      logout: 'Wyloguj',
      delete_chat: 'Usuń czat',
      delete: 'Usuń',
      copy: 'Kopiuj',
   },
   pages: {
      signup: {
         title: 'Utwórz konto',
         subtitle:
            'Rejestrując się lub logując, zgadzasz się z naszymi warunkami korzystania i polityką prywatności',
         btn: 'Utwórz konto',
         haveAnAccount: 'Masz konto?',
         login: 'Zaloguj się',
      },
      login: {
         title: 'Zaloguj się',
         forgotPass: 'Zapomniałeś hasła?',
         btn: 'Zaloguj się',
         howTo: 'Jak być bliżej?',
         createAccount: 'Utwórz konto',
      },
      forgot_pass: {
         title: 'Zresetuj hasło',
         subtitle:
            'Wprowadź swój adres e-mail, a my wyślemy link do resetowania hasła',
         btn: 'Wyślij link',
         title2: 'Instrukcje wysłane',
         subtitle2:
            "E-mail z linkiem do ustawienia nowego hasła został wysłany na Twój adres e-mail. Oprócz sprawdzania folderu 'Skrzynka odbiorcza', sprawdź także folder 'Spam'. E-mail mógł zostać przekierowany tam.",
         backBtn: 'Wróć do strony głównej',
      },
      recovery_pass: {
         title: 'Zresetuj hasło',
         subtitle:
            'Prawie gotowe! Utwórz mocne nowe hasło i wróć do działania!',
         new_pass: 'Nowe hasło',
         confirm_pass: 'Potwierdź hasło',
         btn: 'Zapisz nowe hasło',
      },
      unavailable: {
         title: 'Ta strona jest niedostępna',
         subtitle: 'Przykro nam, nie możesz uzyskać dostępu do tego',
         btn: 'Strona główna',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Wybierz rozmówcę',
         subtitle:
            'Wprowadź imię i nazwisko lub pseudonim osoby, z którą chcesz się komunikować',
         description_hint:
            'Aby uzyskać optymalne wyniki wyszukiwania, podaj opis',
         back_label_link: 'Nie znalazłeś swojego rozmówcy?',
      },
      report_bug: {
         title: 'Zgłoś błąd',
         subtitle: 'Szczegółowo opisz swój problem lub przekaż opinię.',
         title_placeholder: 'Wpisz główny temat swojego raportu…',
         subtitle_placeholder:
            'Wprowadź szczegółowy przegląd zawartości raportu…',
      },
      delete_conversation: {
         title: 'Usuń konwersację',
         subtitle: 'Czy na pewno chcesz usunąć tę rozmowę?',
      },
      delete_message: {
         title: 'Usuń wiadomość',
         subtitle: 'Czy na pewno chcesz usunąć tę wiadomość?',
      },
   },
   conversation: {
      placeholder: 'Napisz wiadomość…',
   },
   settings: {
      title: 'Ustawienia',
      privacy_and_security: 'Prywatność i bezpieczeństwo',
      language: 'Język',
      report_bug: 'Zgłoś błąd',
      terms_of_use: 'Warunki użytkowania',
   },
   profile: {
      title: 'Edytuj profil',
      bio: 'Biografia',
      bio_optional: 'Biografia (opcjonalnie)',
      bio_hint:
         'W swojej biografii podziel się tym, co chciałbyś, aby Twój partner do rozmowy dowiedział się o Tobie. Pomoże to w dostarczaniu odpowiedzi lepiej dostosowanych do Twoich potrzeb.',
   },
   validation_messages: {
      required: 'To pole jest wymagane.',
      titleMaxLength: 'Tytuł może mieć maksymalnie {{count}} znaków.',
      invalidEmailFormat: 'Nieprawidłowy format e-maila.',
      passLength: 'Hasło musi mieć co najmniej {{count}} znaków',
      passUppercase: 'Hasło musi zawierać co najmniej jedną dużą literę',
      passSpecialChar: 'Hasło musi zawierać co najmniej jeden znak specjalny',
      emailAlreadyRegistered: 'Ten e-mail jest już zarejestrowany.',
      failedLogin: 'Logowanie nie powiodło się. Sprawdź swoje dane.',
      matchPass: 'Hasła muszą się zgadzać',
      confirmPass: 'Potwierdzenie hasła jest wymagane',
   },
}

export default translation
