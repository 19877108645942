import { IModalState } from '../../models/storeModels/IModal'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IModalState = {
   onClick: null,
   open: false,
   type: null,
   modalBody: {
      children: null,
      title: null,
   },
}

const modalsSlice = createSlice({
   name: 'modal',
   initialState,
   reducers: {
      openModal: (state, action: PayloadAction<IModalState>) => {
         state.onClick = action.payload.onClick
         state.open = action.payload.open
         state.type = action.payload.type
         state.modalBody = action.payload.modalBody
      },
      closeModal: (state) => {
         state.onClick = initialState.onClick
         state.open = initialState.open
         state.type = initialState.type
         state.modalBody = initialState.modalBody
      },
   },
})

export const { openModal, closeModal } = modalsSlice.actions
export default modalsSlice.reducer
