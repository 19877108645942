import React from 'react'
export interface IRoute {
   path: string
   component: React.ComponentType
}

export const ROUTES = {
   HOME: '/' as const,
   LOGIN: '/login' as const,
   SIGNUP: '/signup' as const,
   FORGOT_PASS: '/forgot-password' as const,
   RECOVERY_PASS: '/recovery-password/:token' as const,
   ACTIVATE: '/activate/:link' as const,
   TERMS: '/terms-and-conditions' as const,
   PRIVACY: '/privacy-policy' as const,
   CONTACT_US: '/contact-us' as const,
}
