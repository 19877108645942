import { NavigationEvents } from '../models/analitycs'
import mixpanel from 'mixpanel-browser'

class Analytics {
   static identify(userId: string): void {
      mixpanel.identify(userId)
      // window.dataLayer.push({
      //    event: 'identify',
      //    userId: userId,
      // });
   }

   static track(trackName: string, params: any): void {
      mixpanel.track(trackName, params)
      // window.dataLayer.push({
      //    event: trackName,
      //    ...params,
      // });
   }

   static page(params: any): void {
      // window.dataLayer.push({
      //    event: 'page_view',
      //    page_name:pageName,
      //    ...params,
      // });
      mixpanel.track(NavigationEvents.PAGE_VIEWED, params)
   }

   static reset(): void {
      mixpanel.reset()
      // window.dataLayer.push({
      //    event: 'reset',
      // });
   }
}

export default Analytics
