import { device } from '../../theme'
import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const ErrorContainer = styled(Box)`
   user-select: none;
   display: flex;
   width: 100%;
   height: 100vh;
   background: ${({ theme }) => theme.palette.main.background};
   box-shadow: 0 20px 40px 0 rgba(49, 34, 46, 0.2);
   flex-direction: column;
   align-items: center;
   position: relative;
`

export const ErrorContent = styled(Box)`
   user-select: none;
   display: flex;
   height: inherit;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   h1 {
      font-family: Poppins, sans-serif;
      font-size: 200px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      color: transparent;
      -webkit-text-stroke: 2px ${({ theme }) => theme.palette.blue.primary};
      text-stroke: 2px ${({ theme }) => theme.palette.blue.primary};
   }

   @media (max-width: 600px) {
      h1 {
         font-size: 166px;
      }
   }
   @media ${device.xs.max} {
      h1 {
         font-size: 130px;
      }
   }
`

export const ErrorHeaderContainer = styled(Box)`
   width: 100%;
   box-sizing: border-box;
   padding: 36px 0 0 40px;
`
