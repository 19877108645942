import { CookiesBlockContainer, CookiesLink } from './styles'
import React, { useLayoutEffect, useState } from 'react'
import { ROUTES } from '../../models/router/IRoute'
import { Typography } from '@mui/material'

const CookiesBlock = () => {
   const [isHidden, setIsHidden] = useState(false)
   const handleClick = () => {
      setIsHidden(!isHidden)
      localStorage.setItem('cookieConsent', 'true')
   }

   useLayoutEffect(() => {
      const cookieConsent = localStorage.getItem('cookieConsent')
      if (cookieConsent === 'true') {
         setIsHidden(true)
      }
   }, [])

   return (
      <CookiesBlockContainer hidden={isHidden}>
         <Typography variant={'body2'}>
            This website uses{' '}
            <CookiesLink href={ROUTES.PRIVACY} role="link" tabIndex={0}>
               cookies
            </CookiesLink>
            .
         </Typography>
         <Typography
            variant={'body1'}
            role="button"
            onClick={handleClick}
            tabIndex={0}
         >
            Accept
         </Typography>
      </CookiesBlockContainer>
   )
}

export default CookiesBlock
