import { setAccessToken } from './authService'
import { checkAuth } from './api/userApi'
import axios from 'axios'

export const API_URL = process.env.REACT_APP_API_URL

const api = axios.create({
   baseURL: API_URL,
   withCredentials: true,
})

api.interceptors.request.use((config) => {
   config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
   return config
})

api.interceptors.response.use(
   (config) => config,
   async (error) => {
      if (error.response.status === 400) {
         return Promise.reject(error.response)
      }

      if (error.response.status === 401) {
         try {
            const originalRequest = error.config
            await checkAuth().then((res) =>
               setAccessToken(res.data.accessToken)
            )
            return api.request(originalRequest)
         } catch (e) {
            console.log(e)
         }
      }
      return Promise.reject(error)
   }
)

export default api
