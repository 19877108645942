import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store'

import React, { useRef, useCallback, MutableRefObject, RefObject } from 'react'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useScrollToBottom = (): [
   MutableRefObject<HTMLElement | null>,
   () => void
] => {
   const ref = useRef<HTMLElement | null>(null)

   const scrollToBottom = useCallback(() => {
      if (ref.current) {
         ref.current.scrollTop = ref.current.scrollHeight
      }
   }, [])

   return [ref, scrollToBottom]
}

export const useScrollToRef = (): {
   createRef: (id: string) => RefObject<HTMLDivElement>
   scrollToRef: (id: string) => void
} => {
   const elementRefs = useRef<{ [key: string]: RefObject<HTMLDivElement> }>({})

   const createRef = useCallback((id: string) => {
      if (!elementRefs.current[id]) {
         elementRefs.current[id] = React.createRef<HTMLDivElement>()
      }
      return elementRefs.current[id]
   }, [])

   const scrollToRef = useCallback((id: string) => {
      elementRefs.current[id]?.current?.scrollIntoView({
         behavior: 'smooth',
         block: 'center',
      })
   }, [])

   return { createRef, scrollToRef }
}
