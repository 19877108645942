import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import useSessionTimer from './hooks/Analitycs/useSessionTimer'
import { GoogleOAuthProvider } from '@react-oauth/google'
import CookiesBlock from './components/CookiesBlock'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider } from 'react-i18next'
import GlobalModal from './components/Modals'
import Analytics from './utils/analytics'
import Error from './components/Error'
import { Provider } from 'react-redux'
import i18n from './utils/i18n/i18n'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import AppRouter from './router'
import store from './store'
import theme from './theme'
import React from 'react'
import 'normalize.css'

const AppContainer = styled(Box)`
   background: ${({ theme }) => theme.palette.black.secondary};
   box-sizing: border-box;
   display: flex;
   flex-direction: row;
   height: 100%;
   width: 100%;
   overflow: auto;
   position: fixed;
`

const Main: React.FC = () => {
   useSessionTimer()

   return (
      <AppContainer>
         <Analytics />
         <AppRouter />
         <GlobalModal />
         <CookiesBlock />
      </AppContainer>
   )
}

const App: React.FC = () => {
   return (
      <React.StrictMode>
         <GoogleOAuthProvider
            clientId={`${process.env.REACT_APP_GOOGLE_CLOUD_CLIENT_ID}`}
         >
            <Provider store={store}>
               <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                     <I18nextProvider i18n={i18n} defaultNS={'translation'}>
                        <ErrorBoundary fallback={<Error />}>
                           <Main />
                        </ErrorBoundary>
                     </I18nextProvider>
                  </ThemeProvider>
               </StyledEngineProvider>
            </Provider>
         </GoogleOAuthProvider>
      </React.StrictMode>
   )
}

export default App
