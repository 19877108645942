import { Button, CircularProgress as Circular } from '@mui/material'
import { drumRollAnimation } from '../styles/animation'
import { styled } from '@mui/system'

export const SignUpBtn = styled(Button)`
   transition: all 0.3s ease-in-out;
   overflow: hidden;
   text-transform: none;
   border-radius: 24px;
   width: 248px;
   height: 55px;
   color: ${({ theme }) => theme.palette.white.secondary};
   background-color: ${({ theme }) => theme.palette.blue.primary};
   padding: 0;
   border: none;

   p {
      font-weight: 600;
   }

   &:hover {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.palette.white.secondary};
      p {
         animation: ${drumRollAnimation} 0.5s
            cubic-bezier(0.55, 0.085, 0.68, 0.53) 1 forwards;
      }
   }

   &:disabled {
      color: ${({ theme }) => theme.palette.grey[500]};
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.palette.white.secondary};
      cursor: not-allowed;
   }
`

export const CircularProgress = styled(Circular)`
   color: ${({ theme }) => theme.palette.blue.primary};
`
