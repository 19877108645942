import { TextField } from '@mui/material'
import { styled } from '@mui/system'

export const FormInput = styled(TextField)`
   margin: 6px 0;
   width: 100%;
   box-sizing: border-box;
   touch-action: manipulation;

   & .MuiInputBase-root {
      background: ${({ theme }) => theme.palette.gray.primary};
      border-radius: 16px;
   }

   & .MuiInputBase-sizeSmall {
      min-height: 44px;
   }

   & .MuiInputBase-multiline {
      height: inherit;
   }

   & .MuiInputLabel-root {
      color: ${({ theme }) => theme.palette.white.secondary};
   }

   & .MuiOutlinedInput-notchedOutline {
      border: none;
   }

   .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.palette.gray.primary};
      box-sizing: border-box;
      border-radius: 16px;
   }

   &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.palette.blue.primary};
   }

   & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid ${({ theme }) => theme.palette.blue.primary};
   }

   & .MuiInputLabel-root.Mui-focused {
      color: ${({ theme }) => theme.palette.blue.primary};
   }

   & .MuiInputBase-input:-webkit-autofill,
   & .MuiInputBase-input:-webkit-autofill:hover,
   & .MuiInputBase-input:-webkit-autofill:focus,
   & .MuiInputBase-input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 100px #262626 inset;
      -webkit-text-fill-color: ${({ theme }) => theme.palette.white.primary};
      border: none;
      outline: none;
      border-radius: 16px;
   }

   & .MuiInputBase-input:-webkit-autofill::first-line {
      font-family: inherit;
      font-size: inherit;
   }
`
