import { SignUpBtn as Btn } from '../../../../../../components/common/Buttons'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const MobHeaderContainer = styled(Box)`
   width: 100%;
   padding: 20px;
   display: flex;
   justify-content: space-between;
   box-sizing: border-box;
   align-items: center;
   background: ${({ theme }) => theme.palette.black.primary};
`

export const SignUpBtn = styled(Btn)`
   width: 76px;
   height: 40px;
`

export const LoginBtn = styled(Typography)`
   cursor: pointer;
   font-weight: 600;
`
