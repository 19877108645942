import { SignUpBtn } from '../../components/common/Buttons'
import { device } from '../../theme'
import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const ActivateContainer = styled(Box)`
   user-select: none;
   display: flex;
   width: 100%;
   height: 100vh;
   background: ${({ theme }) => theme.palette.main.background};
   box-shadow: 0 20px 40px 0 rgba(49, 34, 46, 0.2);
   flex-direction: column;
   align-items: center;
   position: relative;
   padding: 0 122px;

   @media ${device.md.max} {
      padding: 0 40px;
   }
   @media (max-width: 600px) {
      padding: 0 20px;
   }
`

export const HomeBtn = styled(SignUpBtn)`
   margin: 32px 0 0 0;
   width: 100px;
   color: ${({ theme }) => theme.palette.white.primary};
`

export const ActivateHeaderContainer = styled(Box)`
   width: 100%;
   box-sizing: border-box;
   padding: 36px 0 0 0;
   user-select: none;
   img {
      cursor: pointer;
   }
`

export const ActivateContent = styled(Box)`
   user-select: none;
   display: flex;
   height: inherit;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: 80%;
   text-align: center;
`
