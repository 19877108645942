const translation = {
   common: {
      cancel: 'cancel',
      delete: 'delete',
      create: 'create',
      check: 'check',
      send: 'send',
      update: 'update',
      title: 'title',
      subtitle: 'subtitle',
      search: 'Search',
      name: 'Name',
      description: 'Description',
      description_optional: 'Description (optional)',
      email: 'Email',
      password: 'Password',
   },
   search: {
      empty: 'No messages found',
      found: 'message found',
      found_plural: 'messages found',
   },
   context_menu: {
      settings: 'Settings',
      report_bug: 'Report bug',
      logout: 'Logout',
      delete_chat: 'Delete chat',
      delete: 'Delete',
      copy: 'Copy',
   },
   pages: {
      signup: {
         title: 'Create an account',
         subtitle:
            'By signing up or logging in, you agree to our Terms of use and Privacy policy',
         btn: 'Create an account',
         haveAnAccount: 'Have an account?',
         login: 'Log in',
      },
      login: {
         title: 'Sing in',
         forgotPass: 'Forgot your password?',
         btn: 'Log in',
         howTo: 'How to Be > Closer?',
         createAccount: 'Create an account',
      },
      forgot_pass: {
         title: 'Reset password',
         subtitle: "Enter your email, and we'll send a link for password reset",
         btn: 'Send link',
         title2: 'Instructions Sent',
         subtitle2:
            "An email containing a link to set a new password has been sent to your email address. Besides checking your 'Inbox' folder, please also verify the 'Spam' folder of your email inbox. The email might have been directed there.",
         backBtn: 'Back home',
      },
      recovery_pass: {
         title: 'Reset password',
         subtitle:
            'Almost there! Create a strong new password and jump back into action!',
         new_pass: 'New password',
         confirm_pass: 'Confirm password',
         btn: 'Save New Password',
      },
      unavailable: {
         title: "This page isn't available",
         subtitle: "Sorry, you can't access this",
         btn: 'Home',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Choose your interlocutor',
         subtitle:
            'Please enter the first and last name or nickname of the person with whom you want to communicate',
         description_hint:
            'For optimal search results, please provide a description',
         back_label_link: "Didn't find your interlocutor?",
      },
      report_bug: {
         title: 'Report bug',
         subtitle: 'Please detail your problem or provide feedback.',
         title_placeholder: 'Type in the main subject of your report…',
         subtitle_placeholder:
            'Enter a detailed overview of the report contents…',
      },
      delete_conversation: {
         title: 'Delete Conversation',
         subtitle: 'Are you sure to want to delete this conversation?',
      },
      delete_message: {
         title: 'Delete Message',
         subtitle: 'Are you sure you want to delete this message?',
      },
   },
   conversation: {
      placeholder: 'Write a message…',
   },
   settings: {
      title: 'Settings',
      privacy_and_security: 'Privacy and Security',
      language: 'Language',
      report_bug: 'Report bug',
      terms_of_use: 'Terms of use',
   },
   profile: {
      title: 'Edit Profile',
      bio: 'Bio',
      bio_optional: 'Bio (optional)',
      bio_hint:
         "Within your bio, kindly share what you'd like your conversation partner to learn about you. This will aid in delivering responses that are better tailored and more precise to your needs.",
   },
   privacy: {
      change_pass: 'Change password',
      MFA: 'Two-step verification',
   },
   validation_messages: {
      required: 'This field is required.',
      titleMaxLength: 'Title can be at most {{count}} characters.',
      invalidEmailFormat: 'Invalid email format.',
      passLength: 'Password must be at least {{count}} characters',
      passUppercase: 'Password must contain at least one uppercase letter',
      passSpecialChar: 'Password must contain at least one special character',
      emailAlreadyRegistered: 'This email is already registered.',
      failedLogin: 'Failed to login. Please check your credentials.',
      matchPass: 'Passwords must match',
      confirmPass: 'Confirm Password is required',
   },
}

export default translation
