// import TagManager from 'react-gtm-module'
import React, { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

const Analytics = () => {
   // const GTM_ID = process.env.REACT_APP_GTM_ID
   const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN
   useEffect(() => {
      //    TagManager.initialize({
      //       gtmId: GTM_ID,
      //    })
      if (MIXPANEL_TOKEN !== undefined) {
         mixpanel.init(MIXPANEL_TOKEN)
      } else {
         console.error('MIXPANEL_TOKEN is undefined')
      }
   }, [MIXPANEL_TOKEN])

   return <></>
}

export default Analytics
