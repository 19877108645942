import { InteractionEvents, PageNames } from '../../../../models/analitycs'
import AnalyticsService from '../../../../services/analyticsService'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../models/router/IRoute'
import DesktopHeader from './componets/DesktopHeader'
import MobHeader from './componets/MobHeader'
import { useMediaQuery } from '@mui/material'
import React from 'react'

const DashboardHeader = () => {
   const navigate = useNavigate()
   const location = useLocation()
   const handleLoginClick = () => {
      AnalyticsService.track(InteractionEvents.HEADER_LOGIN_BTN_CLICKED, {
         path_name: `${location.pathname}`,
         page_name: PageNames.DASHBOARD,
      })
      navigate(ROUTES.LOGIN)
   }

   const handleSignUpClick = () => {
      AnalyticsService.track(InteractionEvents.HEADER_SIGNUP_BTN_CLICKED, {
         path_name: `${location.pathname}`,
         page_name: PageNames.DASHBOARD,
      })
      navigate(ROUTES.SIGNUP)
   }
   const isMobile = useMediaQuery(() => '(max-width: 600px)')
   return (
      <>
         {isMobile ? (
            <MobHeader
               handleLogin={handleLoginClick}
               handleSignUp={handleSignUpClick}
            />
         ) : (
            <DesktopHeader
               handleLogin={handleLoginClick}
               handleSignUp={handleSignUpClick}
            />
         )}
      </>
   )
}

export default DashboardHeader
