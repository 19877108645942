import { checkOldPass, updatePass } from '../../services/api/passApi'
import { AuthResponse } from '../../models/response/authResponse'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IPassState } from '../../models/storeModels/IPass'
import { IGlobalState } from '../../models/storeModels'

const initialState: IPassState = {
   isMatched: false,
   loading: false,
   error: null,
}

export const checkPassword = createAsyncThunk<
   AuthResponse,
   { password: string },
   { rejectValue: string }
>('password/check', async (payload, { rejectWithValue }) => {
   try {
      const response = await checkOldPass(payload.password)
      return response.data
   } catch (error: any) {
      return rejectWithValue(error.data.message)
   }
})

export const changePassword = createAsyncThunk<
   AuthResponse,
   { password: string; hint?: string },
   { rejectValue: string }
>('password/change', async (payload, { rejectWithValue }) => {
   try {
      const response = await updatePass(payload)
      return response.data
   } catch (error: any) {
      return rejectWithValue(error.response?.data)
   }
})

const passSlice = createSlice({
   name: 'password',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(checkPassword.pending, (state) => {
            state.isMatched = false
            state.loading = true
            state.error = null
         })
         .addCase(checkPassword.fulfilled, (state, action: any) => {
            state.isMatched = action.payload.success
            state.loading = false
            state.error = null
         })
         .addCase(checkPassword.rejected, (state, action: any) => {
            state.loading = false
            state.isMatched = false
            state.error = action.payload
         })
         .addCase(changePassword.pending, (state) => {
            state.loading = true
            state.error = null
         })
         .addCase(changePassword.fulfilled, (state, action) => {
            state.loading = false
            state.error = null
         })
         .addCase(changePassword.rejected, (state, action: any) => {
            state.loading = false
            state.error = action.payload
         })
   },
})

export const selectIsMatched = (state: IGlobalState) => state.password.isMatched
export const selectLoading = (state: IGlobalState) => state.password.loading
export const selectError = (state: IGlobalState) => state.password.error

export default passSlice.reducer
