import {
   IInterlocutorOption,
   IInterlocutorOptionResponse,
} from '../../models/storeModels/IConveration'
import api from '../apiService'

export const getInterlocutorsOptions = async (
   interlocutor: IInterlocutorOptionResponse
) => {
   try {
      const { name, description, userLanguage } = interlocutor
      return api.get('/conversation/options', {
         params: {
            name,
            ...(description && { description }),
            ...(userLanguage && { userLanguage }),
         },
      })
   } catch (e) {
      throw e
   }
}
export const createConversation = async (interlocutor: IInterlocutorOption) => {
   try {
      return await api.post('/conversation', { interlocutor })
   } catch (e) {
      throw e
   }
}

export const getAllConversations = async () => {
   return api.get('/conversation')
}

export const deleteConversation = (id: string) => {
   return api.delete('/conversation', { data: { id } })
}
