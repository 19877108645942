import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Unavailable from './pages/Unavailable/Unavailable'
import routes from './models/router/routes'
import React from 'react'

const AppRouter: React.FC = () => {
   return (
      <Router>
         <Routes>
            {routes.map((route, i) => (
               <Route key={i} path={route.path} element={<route.component />} />
            ))}
            <Route path="*" element={<Unavailable />} />
         </Routes>
      </Router>
   )
}

export default AppRouter
