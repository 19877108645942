const translation = {
   common: {
      cancel: 'لغو',
      delete: 'حذف',
      create: 'ایجاد کردن',
      check: 'بررسی',
      send: 'ارسال',
      update: 'بروزرسانی',
      title: 'عنوان',
      subtitle: 'زیر عنوان',
      search: 'جستجو',
      name: 'نام',
      description: 'توضیحات',
      description_optional: 'توضیحات (اختیاری)',
      email: 'ایمیل',
      password: 'رمز عبور',
   },
   search: {
      empty: 'پیامی یافت نشد',
      found: 'پیام یافت شد',
   },
   context_menu: {
      settings: 'تنظیمات',
      report_bug: 'گزارش خطا',
      logout: 'خروج',
      delete_chat: 'حذف گپ',
      delete: 'حذف',
      copy: 'کپی',
   },
   pages: {
      signup: {
         title: 'ایجاد حساب کاربری',
         subtitle:
            'با ثبت نام یا ورود، شما با قوانین استفاده و سیاست حفظ حریم خصوصی ما موافقت می کنید',
         btn: 'ایجاد حساب کاربری',
         haveAnAccount: 'حساب کاربری دارید؟',
         login: 'ورود',
      },
      login: {
         title: 'ورود',
         forgotPass: 'رمز عبور خود را فراموش کرده اید؟',
         btn: 'ورود',
         howTo: 'چگونه نزدیکتر باشیم؟',
         createAccount: 'ایجاد حساب کاربری',
      },
      forgot_pass: {
         title: 'بازنشانی رمز عبور',
         subtitle:
            'ایمیل خود را وارد کنید و ما یک لینک برای بازنشانی رمز عبور برای شما ارسال می کنیم',
         btn: 'ارسال لینک',
         title2: 'دستورالعمل ها ارسال شد',
         subtitle2:
            'یک ایمیل حاوی لینک برای تنظیم یک رمز عبور جدید به آدرس ایمیل شما ارسال شده است. علاوه بر بررسی پوشه "صندوق ورودی" خود، لطفاً پوشه "هرزنامه" صندوق ایمیل خود را نیز بررسی کنید. ایمیل ممکن است به آنجا هدایت شده باشد.',
         backBtn: 'بازگشت به خانه',
      },
      recovery_pass: {
         title: 'بازنشانی رمز عبور',
         subtitle:
            'تقریباً انجام شد! گذرواژه جدید قوی ای ایجاد کنید و دوباره به فعالیت بپردازید!',
         new_pass: 'رمز عبور جدید',
         confirm_pass: 'تایید رمز عبور',
         btn: 'ذخیره رمز عبور جدید',
      },
      unavailable: {
         title: 'این صفحه در دسترس نیست',
         subtitle: 'متاسفانه، شما نمی‌توانید به این دسترسی داشته باشید',
         btn: 'خانه',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'انتخاب مخاطب',
         subtitle:
            'لطفاً نام و نام خانوادگی یا نام مستعار فردی که می خواهید با او ارتباط برقرار کنید را وارد کنید',
         description_hint: 'برای نتایج جستجوی بهینه، لطفاً یک توضیح ارائه دهید',
         back_label_link: 'مخاطب خود را پیدا نکردید؟',
      },
      report_bug: {
         title: 'گزارش خطا',
         subtitle: 'لطفاً مشکل خود را با جزئیات گزارش دهید یا بازخورد بدهید.',
         title_placeholder: 'موضوع اصلی گزارش خود را وارد کنید...',
         subtitle_placeholder: 'یک بررسی جامع از محتوای گزارش را وارد کنید...',
      },
      delete_conversation: {
         title: 'حذف گفتگو',
         subtitle: 'آیا مطمئن هستید که می خواهید این گفتگو را حذف کنید؟',
      },
      delete_message: {
         title: 'حذف پیام',
         subtitle: 'آیا مطمئن هستید که می خواهید این پیام را حذف کنید؟',
      },
   },
   conversation: {
      placeholder: 'پیامی بنویسید…',
   },
   settings: {
      title: 'تنظیمات',
      privacy_and_security: 'حفظ حریم خصوصی و امنیت',
      language: 'زبان',
      report_bug: 'گزارش خطا',
      terms_of_use: 'قوانین استفاده',
   },
   profile: {
      title: 'ویرایش پروفایل',
      bio: 'زندگی نامه',
      bio_optional: 'زندگی نامه (اختیاری)',
      bio_hint:
         'در زندگی نامه خود، لطفاً اطلاعاتی را که می خواهید همجنبه گفتگوی خود بداند به اشتراک بگذارید. این اطلاعات به ارائه پاسخ های بهتر و دقیقتر کمک می کند.',
   },
   validation_messages: {
      required: 'این فیلد الزامی است.',
      titleMaxLength: 'عنوان می تواند حداکثر {{count}} حرف داشته باشد.',
      invalidEmailFormat: 'فرمت ایمیل نامعتبر است.',
      passLength: 'رمز عبور باید حداقل {{count}} حرف داشته باشد',
      passUppercase: 'رمز عبور باید حداقل یک حرف بزرگ داشته باشد',
      passSpecialChar: 'رمز عبور باید حداقل یک کاراکتر ویژه داشته باشد',
      emailAlreadyRegistered: 'این ایمیل قبلاً ثبت شده است.',
      failedLogin: 'ورود ناموفق بود. لطفاً اطلاعات خود را بررسی کنید.',
      matchPass: 'رمزهای عبور باید مطابقت داشته باشند',
      confirmPass: 'تایید رمز عبور الزامی است',
   },
}

export default translation
