import { selectUser } from '../../store/slices/userSlice'
import Analytics from '../../services/analyticsService'
import { IUser } from '../../models/storeModels/IUser'
import { SessionEvents } from '../../models/analitycs'
import { useState, useEffect } from 'react'
import { useAppSelector } from '../index'

function useSessionTimer() {
   const [startTime] = useState(Date.now())
   const user = useAppSelector(selectUser) as IUser

   useEffect(() => {
      const handleSessionEnd = () => {
         const endTime = Date.now()
         const duration = endTime - startTime

         Analytics.track(SessionEvents.SESSION_TIME, {
            time_spent: duration,
            ...(user
               ? {
                    user_id: user.id,
                    user_email: user.email,
                    user_activated: user.isActivated,
                 }
               : { user: null }),
         })
         Analytics.reset()
      }

      window.addEventListener('beforeunload', handleSessionEnd)

      return () => {
         window.removeEventListener('beforeunload', handleSessionEnd)
         handleSessionEnd()
      }
   }, [startTime, user])
}

export default useSessionTimer
