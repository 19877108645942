const translation = {
   common: {
      cancel: '取消',
      delete: '删除',
      create: '创建',
      check: '检查',
      send: '发送',
      update: '更新',
      title: '标题',
      subtitle: '副标题',
      search: '搜索',
      name: '名称',
      description: '描述',
      description_optional: '描述（可选）',
      email: '电子邮件',
      password: '密码',
   },
   search: {
      empty: '未找到消息',
      found: '找到的消息',
   },
   context_menu: {
      settings: '设置',
      report_bug: '报告错误',
      logout: '登出',
      delete_chat: '删除聊天',
      delete: '删除',
      copy: '复制',
   },
   pages: {
      signup: {
         title: '创建账户',
         subtitle: '通过注册或登录，您同意我们的使用条款和隐私政策',
         btn: '创建账户',
         haveAnAccount: '已有账户？',
         login: '登录',
      },
      login: {
         title: '登录',
         forgotPass: '忘记密码？',
         btn: '登录',
         howTo: '如何更近？',
         createAccount: '创建账户',
      },
      forgot_pass: {
         title: '重置密码',
         subtitle: '输入您的电子邮件，我们将发送密码重置链接',
         btn: '发送链接',
         title2: '指令已发送',
         subtitle2:
            "包含设置新密码的链接的电子邮件已发送到您的电子邮件地址。除了检查您的'收件箱'文件夹外，请还确认您电子邮件的'垃圾邮件'文件夹。邮件可能已经被定向到那里。",
         backBtn: '返回主页',
      },
      recovery_pass: {
         title: '重置密码',
         subtitle: '快完成了！创建一个强大的新密码，然后重新开始行动！',
         new_pass: '新密码',
         confirm_pass: '确认密码',
         btn: '保存新密码',
      },
      unavailable: {
         title: '此页面不可用',
         subtitle: '对不起，您无法访问此内容',
         btn: '首页',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: '选择您的对话者',
         subtitle: '请输入您想要交流的人的名字和姓氏或昵称',
         description_hint: '为了获得最佳的搜索结果，请提供描述',
         back_label_link: '找不到您的对话者？',
      },
      report_bug: {
         title: '报告错误',
         subtitle: '请详细描述您的问题或提供反馈。',
         title_placeholder: '输入报告的主要主题…',
         subtitle_placeholder: '输入报告内容的详细概述…',
      },
      delete_conversation: {
         title: '删除对话',
         subtitle: '您确定要删除此对话吗？',
      },
      delete_message: {
         title: '删除消息',
         subtitle: '您确定要删除此消息吗？',
      },
   },
   conversation: {
      placeholder: '写消息…',
   },
   settings: {
      title: '设置',
      privacy_and_security: '隐私与安全',
      language: '语言',
      report_bug: '报告错误',
      terms_of_use: '使用条款',
   },
   profile: {
      title: '编辑个人资料',
      bio: '简介',
      bio_optional: '简介（可选）',
      bio_hint:
         '在您的简介中，请分享您希望您的对话伙伴了解的信息。这将有助于更好、更精确地满足您的需求。',
   },
   validation_messages: {
      required: '此字段是必需的。',
      titleMaxLength: '标题最多可以有{{count}}个字符。',
      invalidEmailFormat: '无效的电子邮件格式。',
      passLength: '密码必须至少为{{count}}个字符',
      passUppercase: '密码必须至少包含一个大写字母',
      passSpecialChar: '密码必须至少包含一个特殊字符',
      emailAlreadyRegistered: '此电子邮件已被注册。',
      failedLogin: '登录失败，请检查您的凭证。',
      matchPass: '密码必须匹配',
      confirmPass: '需要确认密码',
   },
}

export default translation
