import {
   DesktopHeaderBtnGroup,
   DesktopHeaderContainer,
   SignUpBtn,
   LoginBtn,
} from './styles'
import HeaderLogo from '../../../../../../components/common/HeaderLogo'
import { PageNames } from '../../../../../../models/analitycs'
import { Typography } from '@mui/material'
import React from 'react'

interface Props {
   handleLogin: () => void
   handleSignUp: () => void
}
const DesktopHeader: React.FC<Props> = ({ handleLogin, handleSignUp }) => {
   return (
      <DesktopHeaderContainer>
         <HeaderLogo pageName={PageNames.DASHBOARD} />
         <DesktopHeaderBtnGroup>
            <LoginBtn onClick={handleLogin} variant="body1">
               Log in
            </LoginBtn>
            <SignUpBtn onClick={handleSignUp}>
               <Typography variant={'body1'}>Create an account</Typography>
            </SignUpBtn>
         </DesktopHeaderBtnGroup>
      </DesktopHeaderContainer>
   )
}

export default DesktopHeader
