const translation = {
   common: {
      cancel: 'annuleren',
      delete: 'verwijderen',
      create: 'aanmaken',
      check: 'controleren',
      send: 'versturen',
      update: 'bijwerken',
      title: 'titel',
      subtitle: 'ondertitel',
      search: 'Zoeken',
      name: 'Naam',
      description: 'Beschrijving',
      description_optional: 'Beschrijving (optioneel)',
      email: 'E-mail',
      password: 'Wachtwoord',
   },
   search: {
      empty: 'Geen berichten gevonden',
      found: 'berichten gevonden',
   },
   context_menu: {
      settings: 'Instellingen',
      report_bug: 'Bug melden',
      logout: 'Uitloggen',
      delete_chat: 'Chat verwijderen',
      delete: 'Verwijderen',
      copy: 'Kopiëren',
   },
   pages: {
      signup: {
         title: 'Maak een account',
         subtitle:
            'Door je aan te melden of in te loggen ga je akkoord met onze Gebruiksvoorwaarden en Privacybeleid',
         btn: 'Account aanmaken',
         haveAnAccount: 'Heb je al een account?',
         login: 'Inloggen',
      },
      login: {
         title: 'Inloggen',
         forgotPass: 'Wachtwoord vergeten?',
         btn: 'Inloggen',
         howTo: 'Hoe dichterbij te komen?',
         createAccount: 'Maak een account',
      },
      forgot_pass: {
         title: 'Wachtwoord resetten',
         subtitle:
            'Voer je e-mail in en we sturen een link om je wachtwoord te resetten',
         btn: 'Link sturen',
         title2: 'Instructies verzonden',
         subtitle2:
            "Een e-mail met een link om een nieuw wachtwoord in te stellen is verzonden naar je e-mailadres. Controleer naast je 'Inbox' ook de 'Spam' map van je e-mail. De e-mail kan daar zijn terechtgekomen.",
         backBtn: 'Terug naar start',
      },
      recovery_pass: {
         title: 'Wachtwoord resetten',
         subtitle:
            'Bijna klaar! Maak een sterk nieuw wachtwoord aan en spring terug in actie!',
         new_pass: 'Nieuw wachtwoord',
         confirm_pass: 'Bevestig wachtwoord',
         btn: 'Nieuw wachtwoord opslaan',
      },
      unavailable: {
         title: 'Deze pagina is niet beschikbaar',
         subtitle: 'Sorry, je kunt dit niet bereiken',
         btn: 'Startpagina',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Kies je gesprekspartner',
         subtitle:
            'Voer de voornaam en achternaam of bijnaam in van de persoon met wie je wilt communiceren',
         description_hint:
            'Geef voor optimale zoekresultaten een beschrijving op',
         back_label_link: 'Je gesprekspartner niet gevonden?',
      },
      report_bug: {
         title: 'Bug melden',
         subtitle: 'Beschrijf gedetailleerd je probleem of geef feedback.',
         title_placeholder: 'Type het hoofdonderwerp van je melding...',
         subtitle_placeholder:
            'Voer een gedetailleerd overzicht van de meldingsinhoud in...',
      },
      delete_conversation: {
         title: 'Gesprek verwijderen',
         subtitle: 'Weet je zeker dat je dit gesprek wilt verwijderen?',
      },
      delete_message: {
         title: 'Bericht verwijderen',
         subtitle: 'Weet je zeker dat je dit bericht wilt verwijderen?',
      },
   },
   conversation: {
      placeholder: 'Schrijf een bericht...',
   },
   settings: {
      title: 'Instellingen',
      privacy_and_security: 'Privacy en beveiliging',
      language: 'Taal',
      report_bug: 'Bug melden',
      terms_of_use: 'Gebruiksvoorwaarden',
   },
   profile: {
      title: 'Profiel bewerken',
      bio: 'Bio',
      bio_optional: 'Bio (optioneel)',
      bio_hint:
         'Deel in je bio wat je wilt dat je gesprekspartner over jou te weten komt. Dit zal helpen bij het leveren van reacties die beter zijn afgestemd op jouw behoeften.',
   },
   validation_messages: {
      required: 'Dit veld is verplicht.',
      titleMaxLength: 'Titel mag maximaal {{count}} tekens bevatten.',
      invalidEmailFormat: 'Ongeldig e-mailformaat.',
      passLength: 'Wachtwoord moet minstens {{count}} tekens bevatten',
      passUppercase: 'Wachtwoord moet minimaal één hoofdletter bevatten',
      passSpecialChar: 'Wachtwoord moet minimaal één speciaal teken bevatten',
      emailAlreadyRegistered: 'Dit e-mailadres is al geregistreerd.',
      failedLogin: 'Inloggen mislukt. Controleer je gegevens.',
      matchPass: 'Wachtwoorden moeten overeenkomen',
      confirmPass: 'Bevestiging van het wachtwoord is verplicht',
   },
}

export default translation
