import { ILatestMessage } from './IMessage'

export interface IInterlocutor {
   name: string
   img: any
}
export interface IConversation {
   id: string
   user_id: string
   interlocutor: IInterlocutor

   createdAt: string
   updatedAt: string
   latestMessage: ILatestMessage
}

export const [IDLE, LOADING, SUCCEEDED, FAILED] = [
   'idle',
   'loading',
   'succeeded',
   'failed',
] as const

export type LoadingStatus =
   | typeof IDLE
   | typeof LOADING
   | typeof SUCCEEDED
   | typeof FAILED

export interface ConversationLoadingStatus {
   fetchStatus: LoadingStatus
   addStatus: LoadingStatus
   deleteStatus: LoadingStatus
   error: string | null
}

export interface IInterlocutorOptionResponse {
   name: string
   description?: string
   userLanguage?: string
}
export interface IInterlocutorOption {
   name: string
   description: string
   id: string
}

export interface IConversationState {
   options: IInterlocutorOption[]
   selectedInterlocutor: IInterlocutorOption
   list: IConversation[]
   fetchStatus: LoadingStatus
   addStatus: LoadingStatus
   deleteStatus: LoadingStatus
   error: string | null
   selectedConversation: IConversation
   prevConversationId: string
   backToList: boolean
}
