import { AuthResponse } from '../../models/response/authResponse'
import { AxiosResponse } from 'axios'
import api from '../apiService'

export const checkOldPass = async (
   password: string
): Promise<AxiosResponse<AuthResponse>> => {
   try {
      return await api.post<AuthResponse>('/password/check', {
         password,
      })
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}

interface IUpdatePassProps {
   password: string
   hint?: string
}

export const updatePass = async (
   data: IUpdatePassProps
): Promise<AxiosResponse<AuthResponse>> => {
   try {
      return await api.put<AuthResponse>('/password/change', data)
   } catch (e) {
      console.error(e)
      return Promise.reject(e)
   }
}
