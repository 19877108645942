const translation = {
   common: {
      cancel: 'annulla',
      delete: 'elimina',
      create: 'crea',
      check: 'verifica',
      send: 'invia',
      update: 'aggiorna',
      title: 'titolo',
      subtitle: 'sottotitolo',
      search: 'Ricerca',
      name: 'Nome',
      description: 'Descrizione',
      description_optional: 'Descrizione (facoltativa)',
      email: 'Email',
      password: 'Password',
   },
   search: {
      empty: 'Nessun messaggio trovato',
      found: 'messaggi trovati',
   },
   context_menu: {
      settings: 'Impostazioni',
      report_bug: 'Segnala un problema',
      logout: 'Esci',
      delete_chat: 'Elimina chat',
      delete: 'Elimina',
      copy: 'Copia',
   },
   pages: {
      signup: {
         title: 'Crea un account',
         subtitle:
            'Registrandoti o accedendo, accetti i nostri Termini di uso e la Privacy policy',
         btn: 'Crea un account',
         haveAnAccount: 'Hai già un account?',
         login: 'Accedi',
      },
      login: {
         title: 'Accedi',
         forgotPass: 'Hai dimenticato la password?',
         btn: 'Accedi',
         howTo: 'Come essere > Più vicini?',
         createAccount: 'Crea un account',
      },
      forgot_pass: {
         title: 'Reimposta password',
         subtitle:
            'Inserisci la tua email e ti invieremo un link per reimpostare la password',
         btn: 'Invia link',
         title2: 'Istruzioni inviate',
         subtitle2:
            "Un'email con un link per impostare una nuova password è stata inviata al tuo indirizzo email. Oltre a controllare la tua cartella 'In arrivo', verifica anche la cartella 'Spam'. L'email potrebbe essere stata indirizzata lì.",
         backBtn: 'Torna alla home',
      },
      recovery_pass: {
         title: 'Reimposta password',
         subtitle:
            'Quasi fatto! Crea una nuova password forte e torna subito in azione!',
         new_pass: 'Nuova password',
         confirm_pass: 'Conferma password',
         btn: 'Salva nuova password',
      },
      unavailable: {
         title: 'Questa pagina non è disponibile',
         subtitle: 'Spiacente, non puoi accedere a questo',
         btn: 'Home',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Scegli il tuo interlocutore',
         subtitle:
            'Inserisci il nome e cognome o il soprannome della persona con cui vuoi comunicare',
         description_hint:
            'Per risultati di ricerca ottimali, fornisci una descrizione',
         back_label_link: 'Non hai trovato il tuo interlocutore?',
      },
      report_bug: {
         title: 'Segnala un problema',
         subtitle: 'Dettaglia il tuo problema o fornisci un feedback.',
         title_placeholder:
            "Inserisci l'oggetto principale della tua segnalazione…",
         subtitle_placeholder:
            'Inserisci una panoramica dettagliata del contenuto della segnalazione…',
      },
      delete_conversation: {
         title: 'Elimina conversazione',
         subtitle: 'Sei sicuro di voler eliminare questa conversazione?',
      },
      delete_message: {
         title: 'Elimina messaggio',
         subtitle: 'Sei sicuro di voler eliminare questo messaggio?',
      },
   },
   conversation: {
      placeholder: 'Scrivi un messaggio…',
   },
   settings: {
      title: 'Impostazioni',
      privacy_and_security: 'Privacy e Sicurezza',
      language: 'Lingua',
      report_bug: 'Segnala un problema',
      terms_of_use: 'Termini di uso',
   },
   profile: {
      title: 'Modifica profilo',
      bio: 'Bio',
      bio_optional: 'Bio (facoltativa)',
      bio_hint:
         'Nella tua bio, condividi gentilmente ciò che vorresti che il tuo interlocutore sapesse di te. Ciò aiuterà a fornire risposte migliori e più precise alle tue esigenze.',
   },
   validation_messages: {
      required: 'Questo campo è obbligatorio.',
      titleMaxLength: 'Il titolo può avere al massimo {{count}} caratteri.',
      invalidEmailFormat: 'Formato email non valido.',
      passLength: 'La password deve avere almeno {{count}} caratteri',
      passUppercase: 'La password deve contenere almeno una lettera maiuscola',
      passSpecialChar:
         'La password deve contenere almeno un carattere speciale',
      emailAlreadyRegistered: 'Questa email è già registrata.',
      failedLogin: 'Accesso non riuscito. Controlla le tue credenziali.',
      matchPass: 'Le password devono corrispondere',
      confirmPass: 'Conferma della password è obbligatoria',
   },
}

export default translation
