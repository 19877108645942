import {
   HomeBtn,
   UnavailableContainer,
   UnavailableContent,
   UnavailableContentSubtitle,
} from './style'
import DashboardHeader from '../Dashboard/components/DashboardHeader'
import { isAuthenticated } from '../../services/authService'
import { ROUTES } from '../../models/router/IRoute'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import React from 'react'

const Unavailable: React.FC = () => {
   const navigate = useNavigate()
   const isAuth = isAuthenticated()
   const { t } = useTranslation()

   return (
      <UnavailableContainer>
         {!isAuth && <DashboardHeader />}
         <UnavailableContent>
            <Typography variant="h2">{t('pages.unavailable.title')}</Typography>
            <UnavailableContentSubtitle variant="h4">
               {t('pages.unavailable.subtitle')}
            </UnavailableContentSubtitle>
            <HomeBtn onClick={() => navigate(ROUTES.HOME)}>
               <Typography>{t('pages.unavailable.btn')}</Typography>
            </HomeBtn>
         </UnavailableContent>
      </UnavailableContainer>
   )
}

export default Unavailable
