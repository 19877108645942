import { keyframes } from '@mui/system'

export const drumScrollAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  50% {
    transform: translateY(25px);  
    opacity: 0;
  }

  51% {
    transform: translateY(-25px);
  }

  52% {
    opacity: 0;
  }

  60%, 100% {
    opacity: 1;
  }
`

export const scrollAnimation = keyframes`
  0%, 20%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
`

export const breatheAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05); 
  }
`

export const drumDownScrollAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  70% {
    transform: translateY(-50%);
    opacity: 0.5;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }

`

export const drumRollAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
    opacity: 1;
  }

  25% {
    transform: translateY(-50%);
    opacity: 0.5;
  }

  50% {
    transform: translateY(-100%);
    opacity: 0;
  }

  51% {
    transform: translateY(100%);
    opacity: 0;
  }

  75% {
    transform: translateY(50%);
    opacity: 0.5;
  }
`

export const drumHiddenAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
    max-height: 51px;
    
  }

  60% {
    transform: translateY(-60%);
    opacity: 0.6;
  }

  70% {
    transform: translateY(-100%);
    opacity: 0.5;
  }

  100% {
    max-height: 0;
    transform: translateY(-200%);
    opacity: 0;
  }
`
export const drumRevealHiddenAnimation = keyframes`
  0% {
    max-height: 0;
    transform: translateY(-200%);
    opacity: 0;
  }

  30% {
    transform: translateY(-100%);
    opacity: 0.5;
  }

  40% {
    transform: translateY(-60%);
    opacity: 0.6;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    max-height: 51px;
  }
`
export const rotateAnimation = keyframes`
   0% { transform: rotateY(0deg); }
   100% { transform: rotateY(180deg); }
`

export const fadeInAnimation = keyframes`
   0% { transform: rotateY(180deg); opacity: 0; }
   100% { transform: rotateY(0deg); opacity: 1; }
`

export const fadeOutAnimation = keyframes`
   0% { transform: rotateY(0deg); opacity: 1; }
   100% { transform: rotateY(180deg); opacity: 0; }
`
