import { isAuthenticated } from '../../services/authService'
import { selectIsDemo } from '../../store/slices/userSlice'
import { useAppSelector } from '../../hooks'
import { shallowEqual } from 'react-redux'
import React, { Suspense } from 'react'

const Dashboard = React.lazy(() => import('../Dashboard'))
const HomeComponent = React.lazy(() => import('./Home'))

const Home: React.FC = () => {
   const isAuth = isAuthenticated()
   const isDemo = useAppSelector(selectIsDemo, shallowEqual)

   const ComponentToRender = isAuth || isDemo ? HomeComponent : Dashboard

   return (
      <Suspense fallback={<></>}>
         <ComponentToRender />
      </Suspense>
   )
}

export default Home
