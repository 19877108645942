import { ISettingsState } from '../../models/storeModels/ISettings'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGlobalState } from '../../models/storeModels'

const initialState: ISettingsState = {
   isSettings: false,
   isProfile: false,
   isLanguage: false,
   isPrivacy: false,
   isChangePass: false,
   isMFA: false,
}

const settingsSlice = createSlice({
   name: 'settings',
   initialState,
   reducers: {
      toggleSettings: (state, action: PayloadAction<boolean>) => {
         state.isSettings = action.payload
      },
      toggleProfile: (state, action: PayloadAction<boolean>) => {
         state.isProfile = action.payload
      },
      toggleLanguage: (state, action: PayloadAction<boolean>) => {
         state.isLanguage = action.payload
      },
      togglePrivacy: (state, action: PayloadAction<boolean>) => {
         state.isPrivacy = action.payload
      },
      toggleChangePass: (state, action: PayloadAction<boolean>) => {
         state.isChangePass = action.payload
      },
      toggleIsMFA: (state, action: PayloadAction<boolean>) => {
         state.isMFA = action.payload
      },
   },
})

export const getIsSettings = (state: IGlobalState) => state.settings.isSettings
export const getIsProfile = (state: IGlobalState) => state.settings.isProfile

export const getIsLanguage = (state: IGlobalState) => state.settings.isLanguage
export const getIsPrivacy = (state: IGlobalState) => state.settings.isPrivacy
export const getIsChangePass = (state: IGlobalState) =>
   state.settings.isChangePass

export const getIsMFA = (state: IGlobalState) => state.settings.isMFA

export const {
   toggleSettings,
   toggleProfile,
   toggleLanguage,
   togglePrivacy,
   toggleChangePass,
   toggleIsMFA,
} = settingsSlice.actions

export default settingsSlice.reducer
