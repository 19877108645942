const translation = {
   common: {
      cancel: 'abbrechen',
      delete: 'löschen',
      create: 'erstellen',
      check: 'überprüfen',
      send: 'senden',
      update: 'aktualisieren',
      title: 'Titel',
      subtitle: 'Untertitel',
      search: 'Suche',
      name: 'Name',
      description: 'Beschreibung',
      description_optional: 'Beschreibung (optional)',
      email: 'E-Mail',
      password: 'Passwort',
   },
   search: {
      empty: 'Keine Nachrichten gefunden',
      found: 'Nachrichten gefunden',
   },
   context_menu: {
      settings: 'Einstellungen',
      report_bug: 'Fehler melden',
      logout: 'Abmelden',
      delete_chat: 'Chat löschen',
      delete: 'löschen',
      copy: 'kopieren',
   },
   pages: {
      signup: {
         title: 'Konto erstellen',
         subtitle:
            'Durch die Anmeldung oder Registrierung stimmen Sie unseren Nutzungsbedingungen und Datenschutzbestimmungen zu',
         btn: 'Konto erstellen',
         haveAnAccount: 'Haben Sie ein Konto?',
         login: 'Anmelden',
      },
      login: {
         title: 'Anmelden',
         forgotPass: 'Passwort vergessen?',
         btn: 'Anmelden',
         howTo: 'Wie kann man näher sein?',
         createAccount: 'Konto erstellen',
      },
      forgot_pass: {
         title: 'Passwort zurücksetzen',
         subtitle:
            'Geben Sie Ihre E-Mail ein und wir senden Ihnen einen Link zum Zurücksetzen des Passworts',
         btn: 'Link senden',
         title2: 'Anweisungen gesendet',
         subtitle2:
            "Eine E-Mail mit einem Link zum Festlegen eines neuen Passworts wurde an Ihre E-Mail-Adresse gesendet. Überprüfen Sie neben Ihrem 'Posteingang' auch den 'Spam'-Ordner Ihrer E-Mail. Die E-Mail könnte dorthin geleitet worden sein.",
         backBtn: 'Zur Startseite',
      },
      recovery_pass: {
         title: 'Passwort zurücksetzen',
         subtitle:
            'Fast geschafft! Erstellen Sie ein starkes neues Passwort und legen Sie wieder los!',
         new_pass: 'Neues Passwort',
         confirm_pass: 'Passwort bestätigen',
         btn: 'Neues Passwort speichern',
      },
      unavailable: {
         title: 'Diese Seite ist nicht verfügbar',
         subtitle: 'Entschuldigung, Sie können darauf nicht zugreifen',
         btn: 'Start',
      },
      dashboard: {},
   },
   modals: {
      create_interlocutor: {
         title: 'Wählen Sie Ihren Gesprächspartner',
         subtitle:
            'Bitte geben Sie den Vor- und Nachnamen oder den Spitznamen der Person ein, mit der Sie kommunizieren möchten',
         description_hint:
            'Geben Sie für optimale Suchergebnisse eine Beschreibung an',
         back_label_link: 'Haben Sie Ihren Gesprächspartner nicht gefunden?',
      },
      report_bug: {
         title: 'Fehler melden',
         subtitle: 'Bitte beschreiben Sie Ihr Problem oder geben Sie Feedback.',
         title_placeholder: 'Geben Sie das Hauptthema Ihres Berichts ein...',
         subtitle_placeholder:
            'Geben Sie eine detaillierte Übersicht über den Berichtsinhalt ein...',
      },
      delete_conversation: {
         title: 'Unterhaltung löschen',
         subtitle: 'Möchten Sie diese Unterhaltung wirklich löschen?',
      },
      delete_message: {
         title: 'Nachricht löschen',
         subtitle: 'Möchten Sie diese Nachricht wirklich löschen?',
      },
   },
   conversation: {
      placeholder: 'Schreiben Sie eine Nachricht...',
   },
   settings: {
      title: 'Einstellungen',
      privacy_and_security: 'Datenschutz und Sicherheit',
      language: 'Sprache',
      report_bug: 'Fehler melden',
      terms_of_use: 'Nutzungsbedingungen',
   },
   profile: {
      title: 'Profil bearbeiten',
      bio: 'Bio',
      bio_optional: 'Bio (optional)',
      bio_hint:
         'Teilen Sie in Ihrer Biografie mit, was Ihr Gesprächspartner über Sie wissen sollte. Dies wird helfen, Antworten zu liefern, die besser auf Ihre Bedürfnisse zugeschnitten sind.',
   },
   validation_messages: {
      required: 'Dieses Feld ist erforderlich.',
      titleMaxLength: 'Der Titel kann maximal {{count}} Zeichen lang sein.',
      invalidEmailFormat: 'Ungültiges E-Mail-Format.',
      passLength: 'Das Passwort muss mindestens {{count}} Zeichen lang sein',
      passUppercase:
         'Das Passwort muss mindestens einen Großbuchstaben enthalten',
      passSpecialChar:
         'Das Passwort muss mindestens ein Sonderzeichen enthalten',
      emailAlreadyRegistered: 'Diese E-Mail ist bereits registriert.',
      failedLogin:
         'Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.',
      matchPass: 'Passwörter müssen übereinstimmen',
      confirmPass: 'Passwortbestätigung ist erforderlich',
   },
}

export default translation
