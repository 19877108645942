export enum UserEvents {
   SIGN_UP = 'sign_up',
   LOGIN = 'login',
   LOGOUT = 'logout',
   ACTIVATED_ACCOUNT = 'activated_account',
   DEACTIVATED_ACCOUNT = 'deactivated_account',
   FORGOT_PASS_SUBMITTED = 'forgot_pass_submitted',
   RECOVERY_PASS_SUBMITTED = 'recovery_pass_submitted',
}

export enum NavigationEvents {
   PAGE_VIEWED = 'page_viewed',
}

export enum InteractionEvents {
   HEADER_LOGO_CLICKED = 'header_logo_clicked',
   HEADER_LOGIN_BTN_CLICKED = 'header_login_btn_clicked',
   HEADER_SIGNUP_BTN_CLICKED = 'header_signup_btn_clicked',
   IOS_BTN_CLICKED = 'ios_btn_clicked',
   ANDROID_BTN_CLICKED = 'android_btn_clicked',
   FORGOT_PASS_BTN_CLICKED = 'forgot_pass_btn_clicked',
   CTA_REDIRECTED_TO_SIGNUP_FROM_LOGIN_CLICKED = 'cta_redirected_to_signup_from_login_clicked',
   CTA_REDIRECTED_TO_LOGIN_FROM_SIGNUP_CLICKED = 'cta_redirected_to_login_from_signup_clicked',

   CONVERSATION_MENU_CLICKED = 'conversation_menu_clicked',
   CONVERSATION_HEADER_MENU_CLICKED = 'conversation_header_menu_clicked',
   CTA_CONVERSATION_CREATED = 'cta_conversation_created',
   CTA_CONVERSATION_DELETED = 'cta_conversation_deleted',
   CTA_MESSAGE_CREATED = 'cta_message_created',
   CTA_MESSAGE_DELETED = 'cta_message_deleted',
   CTA_MESSAGE_COPIED = 'cta_message_copied',
   BURGER_MENU_CLICKED = 'burger_menu_clicked',
}

export enum ErrorEvents {
   ERROR_OCCURRED = 'error_occurred',
}

export enum FeedbackEvents {
   FEEDBACK_SUBMITTED = 'feedback_submitted',
}

export enum FileEvents {
   FILE_UPLOADED = 'file_uploaded',
}

export enum SearchEvents {
   SEARCHBAR_OPENED = 'searchbar_opened',
   SEARCHBAR_CLOSED = 'searchbar_closed',
   SEARCH_CONVERSATION = 'search_conversation',
   SEARCH_MESSAGE = 'search_message',
}

export enum NotificationEvents {
   PUSH_NOTIFICATION_SENT = 'push_notification_sent',
   PUSH_NOTIFICATION_CLICKED = 'push_notification_clicked',
   EMAIL_OPENED = 'email_opened',
}

export enum SessionEvents {
   SESSION_TIME = 'session_time',
   TIME_SPENT_ON_HOME_PAGE = 'time_spent_on_home_page',
   TIME_SPENT_ON_DASHBOARD_PAGE = 'time_spent_on_dashboard_page',
}

export const PageNames = {
   DASHBOARD: 'DASHBOARD',
   HOME: 'HOME',
   LOGIN: 'LOGIN',
   SIGNUP: 'SIGNUP',
   FORGOT_PASS: 'FORGOT_PASS',
   RECOVERY_PASS: 'RECOVERY_PASS',
}
